import React, { Suspense, useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, Navbar } from 'react-bootstrap';
import { storage, storage_ref } from "../../../Helper/firebase";
import Spinner from 'react-bootstrap/Spinner';
import { useForm } from 'react-hook-form';
import { listAll, getMetadata, deleteObject, getDownloadURL } from "firebase/storage";
import { useTranslation } from 'react-i18next';

const NavBarAuth = ({userInfoAdmin, resourceFileNamesList, resourceFileURLsList, allResourcesDictList}) => {

	const [resourceNames, setResourceNames] = useState([])
	const [resourceURLs, setResourceURLs] = useState([])
	const [resourceNamesInOrder, setResourceNamesInOrder] = useState([])
	const { register, handleSubmit, errors, reset } = useForm()
	const { t, i18n } = useTranslation()

	useEffect(() => {
		console.log(allResourcesDictList)

		// update keys for better sorting, issue of missing _FR vs FR, leads to different sorted so removing _ before applying sorting
		Object.keys(allResourcesDictList).map(function(fileName) {
			var updatedFileName = fileName.replace(/_/g, ' ')
			var fileURL = allResourcesDictList[fileName];
			delete allResourcesDictList[fileName];
			allResourcesDictList[updatedFileName] = fileURL;
		  });

		console.log(allResourcesDictList)
		var sortedKeys = Object.keys(allResourcesDictList).sort();
		setResourceNamesInOrder(sortedKeys)
	}, [resourceFileNamesList, resourceFileURLsList])


	return (
		<nav>

			<div className="top-container">
		    	<Link to="/logout">
		    		<button id="admin_button" type="button" className="lang btn btn-dark" key="Logout">
		    			{t("Logout")}
	    			</button>
				</Link>	    	
	    	</div>	  

	    	<Dropdown>
					<Dropdown.Toggle variant="" id="dropdownMenuButton">
						{t("Resources")}
					</Dropdown.Toggle>
					<Dropdown.Menu>
			    	{Array.from(resourceNamesInOrder, (fileName, fileNameIndex) => {
			    		return (
			    			<div>
									<Dropdown.Item target="_blank" key={fileNameIndex} href={allResourcesDictList[fileName]}>{fileName.replace(/_/g, ' ')}</Dropdown.Item>
								</div>
							)
			    	})}
				</Dropdown.Menu>
			</Dropdown>

			<div className="top-container">
		    	<Link to={'/generate-interview-guide'}>
		    		<img src="img/sephora_github_proj/logo.png" alt="Sephora"></img>
		    	</Link> 	

	            <Suspense fallback={'Loading...'}>
	              {userInfoAdmin 
	                ?(<Link to="/admin">
				    		<button id="admin_button" type="button" className="lang btn btn-dark" key="Logout">
				    			{t("Admin")}
			    			</button>
		    			</Link>
	    			):(<div></div>)		                  
	              }
	            </Suspense>				
	    	</div>	    	
	    </nav>
	)
}

export default NavBarAuth