import React, {useState, useEffect} from 'react'
import { uploadResources } from "../../../Helper/APIService";
import SendAlert from '../../General/SendAlert';
import Spinner from 'react-bootstrap/Spinner';
import { useForm } from 'react-hook-form';
import DeleteResourcesComponent from './Resources/DeleteResourcesComponent'


const Resources = ({work_location}) => {
	const [selectedFile, setSelectedFile] = useState({'file_attachments':'', 'file_names':'Choose file...'});
	const [selectedWorkLocation, setSelectedWorkLocation] = useState();
	const [alertInfo, setAlertInfo] = useState({open:false, alertMessage:'', variant:''});
	const [loading, setLoading] = useState(false);
	const { register, handleSubmit, errors, reset } = useForm()

	const fileChangeHandler = (event) => {
		var file_names = [];
		var file_attachments = [];
		// setSelectedFile(event.target.files)
		
		var files = event.target.files
		for (let i = 0; i < files.length; i++) {			
		    file_names.push(files[i].name)
		    file_attachments.push(files[i])
		}
		setSelectedFile({"file_attachments":file_attachments, "file_names":file_names.join(',')})

	};


	function handleSubmitPostRequest(data) {
		setLoading(true)		
		setAlertInfo({open:false, alertMessage:'', variant:''})

		const dataArray = new FormData();

		for (let i = 0; i < selectedFile.file_attachments.length; i++) {			
		    dataArray.append('file', selectedFile.file_attachments[i])
		}
		
		dataArray.append('work_location', work_location)

		
		uploadResources(dataArray).then((resp) => {
			handleResponse(resp, work_location)
			setLoading(false)
			// reset()
			setSelectedFile({'file_attachments':'', 'file_names':'Choose file...'})
    	})
		.catch((error) => {
			console.log(error)
			setLoading(false)
			// reset()
			setSelectedFile({'file_attachments':'', 'file_names':'Choose file...'})
		})
		
	  }

  	function handleResponse(response, work_location) {
		
		if (response=="resource files uploaded successfully"){
		    setAlertInfo({open:true, alertMessage:"Files for " + work_location + " were successfully uploaded", variant:'success'})
		  }		  		
	}
  
	

	return (
		<div>
			<h2><u>{work_location} Resources:</u></h2>
			<br/><br/>
			{alertInfo.open && <SendAlert alertMessage={alertInfo.alertMessage} variant={alertInfo.variant}/>}

			<h2>Upload Files:
				{loading &&
			        <Spinner animation="border" role="status" variant="danger">
	  					<span className="visually-hidden"></span>
					</Spinner>
				}
			</h2>
				<form method="POST" onSubmit={handleSubmit(handleSubmitPostRequest)} enctype="multipart/form-data" content-type="multipart/form-data">			            		            
					<div className="custom-file">
		                <input 
			                type="file" 
			                className="custom-file-input" 
			                name="file" 
			                id="validatedCustomFile" 
			                accept=".pdf" 
			                multiple 
			                required 
			                onChange={fileChangeHandler} 
			                onClick={(event)=> { 
				               event.target.value = null
				          	}}/>
		                <label className="custom-file-label" for="validatedCustomFile">{selectedFile?(selectedFile.file_names):("Choose file...")}</label>		                
		                <div className="invalid-feedback">Example invalid custom file feedback</div>
	            	</div>
	            	<br/>	            		            	
					<button className="btn btn-outline-secondary btn-sm btn-space" style={{minWidth: '5rem', padding: '0', fontSize: '1rem'}} type="submit">Submit</button>
				</form>
				<DeleteResourcesComponent work_location={work_location} file_upload_loading={loading}/>
				<br/>
				<br/>
		</div>
	)
}

export default Resources