import React, {useState, useEffect, Suspense} from 'react'
import { addUsers, removeUsers, updateUsers, updateQuestions } from "../../../../Helper/APIService";
import SendAlert from '../../../General/SendAlert';
import Spinner from 'react-bootstrap/Spinner';
import ExcelTabSelectionForQuestionsUpload from './ExcelTabSelectionForQuestionsUpload'
import { useForm } from 'react-hook-form';
import { store_questions_file } from '../../../../Helper/firebase';
import Alert from 'react-bootstrap/Alert';
// import GeneralAdminPost from '../GeneralAdminComponent/GeneralAdminPost'
// CLEAN isTabSelectionNeeded, HTML has been commented out but need to clean api and delete from component fully, process whole file

const GeneralAdminPost = () => {

	const [selectedFile, setSelectedFile] = useState();
	const [selectedFeature, setSelectedFeature] = useState();
	const [selectedWorkLocation, setSelectedWorkLocation] = useState();
	const [alertInfo, setAlertInfo] = useState({open:false, alertMessage:'', variant:''});
	const [loading, setLoading] = useState(false);
	const [response, setResponse] = useState('');
	const [work_location, setWork_location] = useState('');

	const { register, handleSubmit, errors, reset } = useForm()

	// conditionMet set to true as tabs selection is not open until replace questions is selected
	const [checkboxInfo, setCheckboxInfo] = useState({numberOfCheckboxesSelected:0, conditionMet:true});


	useEffect(() => {
		console.log(response)
  		if (response=="users added successfully"){
		    setAlertInfo({open:true, alertMessage:"Users for " + work_location + " were successfully added", variant:'success'})
		  }		  
		else if (response=="users removed successfully"){
		    setAlertInfo({open:true, alertMessage:"Users for " + work_location + " were successfully deleted", variant:'success'})
		  }
		else if (response=="users updated successfully"){
		    setAlertInfo({open:true, alertMessage:"Users for " + work_location + " were successfully updated", variant:'success'})
		  }
		else if (response=="questions updated successfully"){
	    	setAlertInfo({open:true, alertMessage:"Questions for " + work_location + " were successfully updated", variant:'success'})
		  }

	}, [response])

	const fileChangeHandler = (event) => {
		setSelectedFile(event.target.files[0])
	};


	function createCheckboxes(arrayList){
		
		var checkBoxes = []

		for (var i = 0; i <= arrayList.length-1; i++) {
	      	checkBoxes.push(
		        <div>
		            <label>
                        <input {...register("excel_tabs")} type="checkbox" name="excel_tabs" value={arrayList[i]}/> {arrayList[i]} 
                    </label>
		        </div>
        	)
        }
    	return checkBoxes
	}


	function isTabSelectionNeeded(){

		if (selectedFeature=="update_questions" && selectedWorkLocation=="FSC"){

			var tabs = ['Values', 'Individual Contributor', 'Sr Manager or Manager', 'Sr Director or Director', 'Vice President']
			var checkBoxes = createCheckboxes(tabs)
			checkBoxes.unshift(<div><br/><p><b id='make-bold'>Choose tabs where questions have been been updated for {selectedWorkLocation}:</b></p></div>)
			return checkBoxes
		}
		else if (selectedFeature=="update_questions" && selectedWorkLocation=="Field"){
			var tabs = ['Values', 'Regional Director', 'Store Director', 'Manager', 'Loss Prevention', 'District Manager', 'Lead', 'Beauty Advisor']
			var checkBoxes = createCheckboxes(tabs)
			checkBoxes.unshift(<div><br/><p><b id='make-bold'>Choose tabs where questions have been been updated for {selectedWorkLocation}:</b></p></div>)
			return checkBoxes
		}
	}


	function getSelectedCheckboxes(allCheckboxes, work_location){
		
		var checkboxesChecked = [];

		if (!allCheckboxes){

	 		if (work_location=="FSC"){
	 			checkboxesChecked = ['Values', 'Individual Contributor', 'Sr Manager or Manager', 'Sr Director or Director', 'Vice President']
	 		}

	 		else if (work_location=="Field"){
	 			checkboxesChecked = ['Values', 'Regional Director', 'Store Director', 'Manager', 'Loss Prevention', 'District Manager', 'Lead', 'Beauty Advisor']

 			}

			return checkboxesChecked
 		}

 		else {
 			return allCheckboxes
 		}
 		
	}


	function handleSubmitPostRequest(data) {
		// setLoading(true)		
		// setAlertInfo({open:false, alertMessage:'', variant:''})

		const dataArray = new FormData();
		var work_location = data.work_location
		var feature = data.feature
		var excel_tabs = getSelectedCheckboxes(data.excel_tabs, work_location)
		
		var apiCall

		dataArray.append('file', selectedFile)
		dataArray.append('work_location', work_location)
		dataArray.append('feature', feature)
		dataArray.append('selected_excel_tabs', excel_tabs)

		if (feature == "add_profiles"){
			apiCall = addUsers(dataArray)
		} 
		else if (feature == "remove_profiles"){
			apiCall = removeUsers(dataArray)
		}
		else if (feature == "update_profiles"){
			apiCall = updateUsers(dataArray)
		}
		else if (feature == "update_questions"){
			apiCall = updateQuestions(dataArray)
			// store_questions_file(work_location, selectedFile)
		}

		apiCall.then((resp) => {
			setResponse(resp)
			setWork_location(work_location)

			// handleResponse(resp, work_location)
			// setLoading(false)
			// reset()
			// setSelectedFile('')
    	})
		.catch((error) => {
			console.log(error)
			console.log('error')
			// setLoading(false)
			// reset()
			// setSelectedFile('')
		})
		
	  }
	


	function handleResponse(response, work_location) {
		
		if (response=="users added successfully"){
		    setAlertInfo({open:true, alertMessage:"Users for " + work_location + " were successfully added", variant:'success'})
		  }		  
		else if (response=="users removed successfully"){
		    setAlertInfo({open:true, alertMessage:"Users for " + work_location + " were successfully deleted", variant:'success'})
		  }
		else if (response=="users updated successfully"){
		    setAlertInfo({open:true, alertMessage:"Users for " + work_location + " were successfully updated", variant:'success'})
		  }
		else if (response=="questions updated successfully"){
	    	setAlertInfo({open:true, alertMessage:"Questions for " + work_location + " were successfully updated", variant:'success'})
		  }
	  // setTimeout(function(){ window.location.reload(); }, 3000);
	  // setTimeout(function(){ console.log(loading) }, 1000);
	}
  


	return (
		<div>
						
			{alertInfo.open && <SendAlert alertMessage={alertInfo.alertMessage} variant={alertInfo.variant}/>}
{/*            
            <Suspense fallback={<h1>...</h1>}> 
            	<SendAlert alertMessage={alertInfo.alertMessage} variant={alertInfo.variant}/>
            </Suspense>*/}
{/*            <Suspense fallback={'Loading...'}>
              {loading 
                ?<div></div>
                :<SendAlert alertMessage={alertInfo.alertMessage} variant={alertInfo.variant}/>
              }
            </Suspense>*/}


	        <h2>
	        	Upload File: &nbsp; &nbsp;

	        	{loading &&
			        <Spinner animation="border" role="status" variant="danger">
	  					<span className="visually-hidden"></span>
					</Spinner>
				}
			</h2>

	        <form method="POST" onSubmit={handleSubmit(handleSubmitPostRequest)} enctype="multipart/form-data" content-type="multipart/form-data">			            
	            <div className="form-group">
	                <select  {...register("work_location", {onChange:(e) => setSelectedWorkLocation(e.target.value)})} className="custom-select" required>
	                    <option value="" selected="true" disabled="disabled">Select work location</option>
	                    <option value="Field">Field</option>
	                    <option value="FSC">FSC</option>
	                </select>
	            </div>

	            <div className="form-group">
	                <select {...register("feature", {onChange:(e) => setSelectedFeature(e.target.value)})} className="custom-select" required>
	                    <option value="" selected="true" disabled="disabled">Feature</option>
	                    <option value="add_profiles">Add Profiles</option>
	                    <option value="remove_profiles">Remove Profiles</option>
	                    <option value="update_profiles">Update Profiles</option>
	                    <option value="update_questions">Replace Questions File</option>
	                </select>
	                <div className="invalid-feedback">Example invalid custom select feedback</div>
	            </div>
	            
	            <div className="custom-file">
	                <input type="file" className="custom-file-input" name="file" id="validatedCustomFile" accept=".xlsx" required onChange={fileChangeHandler} onClick={(event)=> { 
               event.target.value = null
          }}/>
	                <label className="custom-file-label" for="validatedCustomFile">{selectedFile?(selectedFile.name):("Choose file...")}</label>		                
	                <div className="invalid-feedback">Example invalid custom file feedback</div>
	            </div>
	            
{/*	            <div id="resource_files">
	            	{isTabSelectionNeeded()}
            	</div>*/}

	            <button className="btn btn-outline-secondary btn-sm btn-space" style={{minWidth: '5rem', padding: '0', fontSize: '1rem'}} type="submit">Submit</button>
	        </form>

		</div>
	)
}

export default GeneralAdminPost