import logo from './logo.svg';
import './App.css';
import {useState, useEffect} from 'react';
import {Deploy} from './Component/Deploy/Deploy';
import QuestionForm from './Component/QuestionForm/QuestionForm';
import Routes from './Component/Routes/Routes';
import Navbar from './Component/Navbar/Navbar';
import IdleTimer from 'react-idle-timer'
import { useIdleTimer } from 'react-idle-timer'
import { auth } from './Helper/firebase';
import { useNavigate } from "react-router-dom";
import {userInfoContext} from './Component/Authentication/userInfoContext';

// import './Helper/EmailHandler/auth_handle_mgmt_query_params'


function App() {
  let navigate = useNavigate();
  const [userInfo, setUserInfo] = useState("")
  
  const handleOnIdle = event => {
    console.log('user is idle', event)
    auth.signOut()
    navigate("/", { replace: true });
    // console.log('last active', getLastActiveTime())
  }

  // const handleOnActive = event => {
  //   console.log('user is active', event)
  //   console.log('time remaining', getRemainingTime())
  // }

  // const handleOnAction = event => {
  //   console.log('user did something', event)
  // }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 60 * 3, // milliseconds to 3 hours
    // timeout: 5000, // milliseconds to hours
    onIdle: handleOnIdle,
    // onActive: handleOnActive,
    // onAction: handleOnAction,
    debounce: 500
  })

  return (
    <div className="App">      
      {/*<Navbar />*/}
      <userInfoContext.Provider value={{userInfo, setUserInfo}}>
        <Routes />
      </userInfoContext.Provider>
    </div>
  );
}

export default App;

 