import React from 'react'
import {Suspense, useEffect, useMemo } from 'react';
import { Routes, Route, BrowserRouter, Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { applyActionCode } from "firebase/auth";
import { auth } from '../../Helper/firebase';

const HandleEmailAction = () => {
	
	let query = useQuery();
  let navigate = useNavigate();

	function useQuery() {
	    const { search } = useLocation();
	    return useMemo(() => new URLSearchParams(search), [search]);
	}

  function handleEmailAction(){

    var mode = query.get('mode')
    var actionCode = query.get('oobCode')

    if (mode=='resetPassword'){
      navigate('/update-password', { state: { actionCode: actionCode } });
    } 
    else if (mode=='verifyEmail'){
    	applyActionCode(auth, actionCode).then((resp) => {
    		navigate('/login?emailVerified=success');
		  }).catch((error) => {
				navigate('/login?emailVerified=fail');				
		  });

    }

  }

	return (
		<div>
			{handleEmailAction()}
		</div>
	)
}

export default HandleEmailAction