import axios from 'axios';
  

  // const sendIntroductionEmail = async (body) => {

  //   return fetch('/api/auth/send-introduction-email',{
  //           'method':'POST',
  //           headers : {
  //             'Content-Type':'application/json'
  //           },
  //     body:JSON.stringify(body)
  //   })
  //   .then(response => response.json())
  //   .catch(error => console.log(error))
  // }
  

  const sendVerificationEmail = async (body) => {

    return fetch('/api/auth/send-verification-email',{
            'method':'POST',
            headers : {
              'Content-Type':'application/json'
            },
      body:JSON.stringify(body)
    })
    .then(response => response.json())
    .catch(error => console.log(error))
  }


  const registerUser = async (body) => {

    return fetch('/api/auth/register',{
            'method':'POST',
            headers : {
              'Content-Type':'application/json'
            },
      body:JSON.stringify(body)
    })
    .then(response => response.json())
    .catch(error => console.log(error))
  }


  const sendResetPasswordEmail = async (body) => {
    
    return fetch('/api/auth/send-reset-password-email',{
            'method':'POST',
            headers : {
              'Content-Type':'application/json'
            },
      body:JSON.stringify(body)
    })
    .then(response => response.json())
    .catch(error => console.log(error))
  }
  

  const addUsers = async (body) => {

    return axios.post('/api/user-management/add-users', body, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            })
            .then(response => response.data)
            .catch(error => console.log(error.response))
  }


  const removeUsers = async (body) => {
    
    return axios.post('/api/user-management/remove-users', body, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => response.data)
        .catch(error => console.log(error))    
  }


  const updateUsers = async (body) => {
    
    return axios.post('/api/user-management/update-users', body, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => response.data)
        .catch(error => console.log(error))    
  }


  const updateQuestions = async (body) => {
    
    return axios.post('/api/question-management/update-questions', body, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => response.data)
        .catch(error => console.log(error))    
  }


  const extractFileName = (disposition) =>{
    if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) { 
            var filename = matches[1].replace(/['"]/g, '');
            return filename
          }
        }
  }


function readFile(response) {
    var fileName = extractFileName(response.headers['content-disposition'])
    const url = window.URL.createObjectURL(new Blob([response.data], {type: 'text/html'}));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('target', "_blank");
    document.body.appendChild(link);
    link.click()
  }


  const downloadFile = (response) => {
    var fileName = extractFileName(response.headers['content-disposition'])
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click()
  }


  const downloadQuestions = async (body) => {
    return axios.post('/api/question-management/download-questions', body, {
          headers:
            {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer',      
        })
        .then((response) => {
          downloadFile(response)          
        })
        .catch(error => console.log(error))    
  }


  const downloadUserTemplate = async (body) => {
    return axios.post('/api/file-management/download-user-template', body, {
          headers:
            {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer',      
        })
        .then((response) => {
          downloadFile(response)          
        })
        .catch(error => console.log(error))    
  }


  const downloadUsers = async (body) => {
    return axios.post('/api/user-management/download-users', body, {
          headers:
            {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer',      
        })
        .then((response) => {
          downloadFile(response)          
        })
        .catch(error => console.log(error))    
  }


  const downloadResource = async (body) => {
    return axios.post('/api/file-management/download-resource', body, {
      // content type will be pdf
          headers:
            {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer',      
        })
        .then((response) => {
          downloadFile(response)          
        })
        .catch(error => console.log(error))    
  }



  const generateQuestionsHTMLFile = async (body) => {

        return fetch('/api/generate-questionsfile-html',{
            'method':'POST',
            headers : {
              'Content-Type':'application/json'
            },
          body:JSON.stringify(body)
        })
        // .then(response => generateAndDownloadHTMLQuestionsFile())
        // .catch(error => console.log(error))

  }


   const generateAndDownloadHTMLQuestionsFile = async (body) => {
    // generateQuestionsHTMLFile(body)
    // downloadQuestionHTMLFile(body)
    return axios.get('/api/download-questionsfile-html', body, {
      // content type will be pdf
          headers:
            {
                'Content-Type': 'text/html'
            },
            responseType: 'arraybuffer',      
        })
        .then((response) => {
          downloadFile(response)          
        })
        .catch(error => console.log(error))    
  }


// openFile

   const generateAndOpenHTMLQuestionsFile = async (body) => {
    return axios.get('/api/download-questionsfile-html', body, {
      // content type will be pdf
          headers:
            {
                'Content-Type': 'text/html'
            },
            responseType: 'arraybuffer',      
        })
        .then((response) => {
          readFile(response)          
        })
        .catch(error => console.log(error.response))    
  }


  // const getAllResourceName = async (body) => {

  //     return axios.post('/api/file-management/get-all-resource-name', body, {
  //         headers: {
  //           "Content-Type": "multipart/form-data"
  //         }
  //       })
  //       .then(response => response.data)
  //       .catch(error => console.log(error))    
  // }


  const uploadResources = async (body) => {

    return axios.post('/api/file-management/upload-resources', body, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => response.data)
        .catch(error => console.log(error))    
  }



  // const deleteResources = async (body) => {

  //   return axios.post('/api/file-management/delete-resources', body, {
  //         headers: {
  //           "Content-Type": "multipart/form-data"
  //         }
  //       })
  //       .then(response => response.data)
  //       .catch(error => console.log(error))    
  // }
    




  // const uploadResourcess = async(body) => {

  //   return axios.post('/api/resource-management/upload-resources', body, {
  //         headers: {
  //           "Content-Type": "multipart/form-data"
  //         }
  //       })
  //       .then(response => response.data)
  //       .catch(error => console.log(error))   
  // }      


  export {
    // sendIntroductionEmail,
    registerUser,
    sendVerificationEmail,
    sendResetPasswordEmail,
    addUsers,
    removeUsers,
    updateUsers,
    updateQuestions,
    downloadUserTemplate,
    downloadUsers,
    downloadResource,
    uploadResources,
    // uploadResourcess,
    downloadQuestions,
    generateQuestionsHTMLFile,
    generateAndDownloadHTMLQuestionsFile,
    generateAndOpenHTMLQuestionsFile
}