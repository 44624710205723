import React, {useState, useEffect} from 'react'
import { addUsers, removeUsers, updateUsers, updateQuestions } from "../../../Helper/APIService";
import SendAlert from '../../General/SendAlert';
import Spinner from 'react-bootstrap/Spinner';
import { useForm } from 'react-hook-form';
import { store_questions_file } from '../../../Helper/firebase';
import GeneralAdminPost from './GeneralAdminComponent/GeneralAdminPost'
import GeneralAdminGet from './GeneralAdminComponent/GeneralAdminGet'

const GeneralAdmin = () => {
	
	return (
		<div>
			{/* connect conditional rendering on hyperlink - General Admin, FSC Resources, Field Resources */}
			
			<h2><u>General Admin</u></h2>
			<br/><br/>
			
			<GeneralAdminPost/>

	        <br/><br/>	

	        <GeneralAdminGet/>        

	    </div>
		
	)
}

export default GeneralAdmin