import React from 'react'
import GeneralAdmin from './Pages/GeneralAdmin'
// import FieldResources from './Pages/FieldResources'
// import FSCResources from './Pages/FSCResources'
import Resources from './Pages/Resources'
import { Link, useNavigate, useLocation } from "react-router-dom";

export class AdminDashboard extends React.Component {
	state = {
		currentPage:'GeneralAdmin'
	}

	renderPage = () => {
	  if (this.state.currentPage=="GeneralAdmin"){
	  	return <GeneralAdmin/>
	  }
	  else if (this.state.currentPage=="FieldResources"){
	  	return <Resources work_location = "Field"/>
	  }
	  else if (this.state.currentPage=="FSCResources"){
	  	return <Resources work_location = "FSC"/>
	  }
	}

	render() {
		return (
			<div>
				<div class="top-container">
					<Link to={'/generate-interview-guide'}>
						<img src="img/sephora_github_proj/logo.png" alt="Sephora"></img>
					</Link>
				</div>
				<div className="container admin">
			        <div className="admin_nav">
			            <a onClick={() => this.setState({currentPage:"GeneralAdmin"})}>General Admin</a> -&nbsp;
			            <a onClick={() => this.setState({currentPage:"FSCResources"})}>FSC Resources</a> -&nbsp; 
			            <a onClick={() => this.setState({currentPage:"FieldResources"})}>Field Resources</a>
			        </div>
			        <br/><br/><br/>
	        		{this.renderPage()}
		
				</div>
			</div>
		)
	}
}

export default AdminDashboard