import React, {useState, useEffect} from 'react'

const StepFooter = ({formValues, currentSelectionPage}) => {

	const [stepClassNames, setStepClassNames] = useState({
		'selected_location':'step',
		'selected_role':'step',
		'selected_values':'step',
		'selected_core_competencies':'step',
		'selected_questions':'step'
	})

	useEffect(() => {	
		// current page after value selected should be finish,
		// if finish and coming back, should be finish + active
		// no value selected, on this page - step 
		// if there is values in form values, update obj state of boxes to be rendered so that selection is shown
		var formValuesKeys = Object.keys(formValues)

		var obj = {}

		// take into account values that have been selected and validated
		formValuesKeys.forEach(function (key, i) {
			if (formValues[key]!=''){			
				obj[key]='step finish'
			}
			else {
				obj[key]='step'
			}
		})

		// active page case
		obj[currentSelectionPage] = obj[currentSelectionPage] + ' active'

		setStepClassNames(obj)
	},[formValues, currentSelectionPage])

	return (
		<div>
			<div className='step_footer'>
				<span className={stepClassNames.selected_location}></span>
				<span className={stepClassNames.selected_role}></span>
				<span className={stepClassNames.selected_values}></span>
				<span className={stepClassNames.selected_core_competencies}></span>
				<span className={stepClassNames.selected_questions}></span>
			</div>
		</div>
	)
}

export default StepFooter