import React, { useEffect, useState, useMemo, useContext } from "react";
import { useForm } from "react-hook-form";
import { useAuthState } from "react-firebase-hooks/auth";
import { isLogin, logout, auth as fb_auth, db } from "../../../Helper/firebase";
import { useTranslation } from "react-i18next";
import { userInfoContext } from "../../Authentication/userInfoContext";
import {
  getDatabase,
  ref,
  onValue,
  set,
  update,
  push,
  increment,
} from "firebase/database";
import { useList } from "react-firebase-hooks/database";
import StepFooter from "./StepFooter/StepFooter";
import SendAlert from "./../../General/SendAlert";
import {
  generateAndDownloadHTMLQuestionsFile,
  generateAndOpenHTMLQuestionsFile,
  generateQuestionsHTMLFile,
} from "../../../Helper/APIService";
import Spinner from "react-bootstrap/Spinner";
import i18next from "i18next";
// import Modal from './Modal';
// import Checkbox from '@mui/material/Button';
// import { getDatabase, ref, set } from "firebase/database";
import { Modal, Button } from "react-bootstrap";
import NavBarAuth from "./NavBarAuth";

const Questions = ({
  prevStep,
  nextStep,
  handleChangeArray,
  formValues,
  questions,
  coreCompQuestions,
  coreCompQuestionsEN,
  valueQuestions,
  valueQuestionsEN,
  valueContent,
  coreCompetenciesContentForSelectedRole,
  userInfoAdmin,
  resourceFileNamesList,
  resourceFileURLsList,
  userInfo,
  allResourcesDictList,
}) => {
  const [numberOfQuestionsSelected, setNumberOfQuestionsSelected] = useState(0);
  const [generateQuestionData, setGenerateQuestionData] = useState({});
  const [
    numberOfModalRequirementSelected,
    setNumberOfModalRequirementSelected,
  ] = useState(0);
  const [modalState, setModalState] = useState(false);
  const [toggleRecommeneded, setToggleRecommeneded] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    open: false,
    alertMessage: "",
    variant: "",
  });
  const lang = i18next.language.substr(0, 2);
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, errors, reset } = useForm({
    shouldUnregister: false,
  });
  const { t, i18n } = useTranslation();

  useEffect(() => {
    handleAlert(numberOfQuestionsSelected);
  }, [numberOfQuestionsSelected]);

  useEffect(() => {
    selectRecommended(toggleRecommeneded);
  }, [toggleRecommeneded]);

  useEffect(() => {
    console.log(formValues);
    window.scrollTo(0, 0);
    // saveAnalytics()
  }, []);

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  function saveAnalytics(question_dict) {
    // console.log(userInfo)
    const userKey = userInfo["Work_Email"]
      .split("@")[0]
      .replace(/[^a-z0-9+]+/gi, "_");

    const date = new Date().toLocaleDateString("en-US", {
      timeZone: "America/Toronto",
    });

    var formattedDate = formatDate(date);

    // var path = 'analytics/page_selection/'+ formValues['selected_location'] + '/' + formattedDate + '/' + userKey

    // selected_location = formValues['selected_location']
    // selected_core_competencies = formValues['selected_core_competencies']
    // selected_values = formValues['selected_values']

    var selected_core_competencies = formValues["selected_core_competencies"];
    var selected_values = formValues["selected_values"];
    var selected_role = formValues["selected_role"];
    var selected_location = formValues["selected_location"];

    // ORIGNAL EVENT
    var pathEventAnalytics =
      "analytics/interview_generated/" +
      formValues["selected_location"] +
      "/" +
      formattedDate;

    var questions_dict_counter = {};
    for (const [core_comp_or_value, questions] of Object.entries(
      question_dict
    )) {
      questions_dict_counter[core_comp_or_value] = {};
      for (let i = 0; i < questions.length; i++) {
        var question = questions[i].replace(/\//g, " or ").replace(/\./g, "");
        questions_dict_counter[core_comp_or_value][question] = increment(1);
      }
    }

    var event = {
      questions: question_dict,
      selected_core_competencies: selected_core_competencies,
      selected_values: selected_values,
      selected_role: selected_role,
      user_key: userKey,
    };

    push(ref(db, pathEventAnalytics), event);
    // console.log(event)
  }

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function handleClickRecommeneded() {
    setToggleRecommeneded(!toggleRecommeneded);
  }

  function printHTMLFile() {
    setLoading(true);

    generateAndOpenHTMLQuestionsFile({})
      .then((resp) => {
        setLoading(false);
        setModalState(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        // reset()
      });
  }

  function downloadHTMLFile() {
    setLoading(true);
    generateAndDownloadHTMLQuestionsFile({})
      .then((resp) => {
        setLoading(false);
        setModalState(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        // reset()
      });
  }

  function closeModal() {
    setModalState(false);
  }

  // const handleDownloadQuestionsGeneratedPDF = async () => {
  //  	generateQuestionsHTMLFile()
  // }

  function selectRecommended(bool) {
    var elements = document.querySelectorAll(
      '[data-question-type="Recommended"]'
    );

    if (bool) {
      setNumberOfQuestionsSelected(numberOfQuestionsSelected + elements.length);
    } else if (numberOfQuestionsSelected != 0 && !bool) {
      setNumberOfQuestionsSelected(numberOfQuestionsSelected - elements.length);
    }

    {
      Array.from(elements, (element, i) => {
        element.checked = bool;
      });
    }
  }

  const handleQuestionChange = (e) => {
    var checkboxState = e.target.checked;

    if (checkboxState) {
      setNumberOfQuestionsSelected(numberOfQuestionsSelected + 1);
    } else {
      setNumberOfQuestionsSelected(numberOfQuestionsSelected - 1);
    }
  };

  const handleModalSelectionChange = (e) => {
    var checkboxState = e.target.checked;

    if (checkboxState) {
      setNumberOfModalRequirementSelected(numberOfModalRequirementSelected + 1);
    } else {
      setNumberOfModalRequirementSelected(numberOfModalRequirementSelected - 1);
    }
  };

  function handleAlert(count) {
    if (count > 8) {
      var exceed = count - 8;

      setAlertInfo({
        open: true,
        alertMessage:
          t("Please select a maximum of ") +
          8 +
          t(" questions.") +
          t("You have selected ") +
          exceed +
          t(" more questions than needed."),
        variant: "danger",
      });
    } else if (count < 2 && count != 0) {
      setAlertInfo({
        open: true,
        alertMessage: "Please select a minimum of 2 questions.",
        variant: "danger",
      });
    } else {
      setAlertInfo({ open: false, alertMessage: "", variant: "" });
    }
  }

  const handleSubmission = (data) => {
    setModalState(!modalState);
    var temp = { questions: {}, general: {} };
    var enQuestionsDict = { questions: {} };
    var enQuestion;

    var checkboxes = document.querySelectorAll("#question");

    {
      Array.from(checkboxes, (checkbox, i) => {
        if (checkbox.checked) {
          temp["questions"][checkbox.name] = [];
          enQuestionsDict["questions"][checkbox.name] = [];
        }
      });
    }

    {
      Array.from(checkboxes, (checkbox, i) => {
        if (checkbox.checked) {
          temp["questions"][checkbox.name].push(checkbox.value);

          enQuestion = checkbox.getAttribute("data-question-en");
          enQuestionsDict["questions"][checkbox.name].push(enQuestion);
        }
      });
    }

    temp["general"]["lang"] = lang;

    temp["general"]["selected_core_competencies"] =
      formValues["selected_core_competencies"];
    temp["general"]["selected_location"] = formValues["selected_location"];
    temp["general"]["selected_role"] = formValues["selected_role"];
    temp["general"]["selected_values"] = formValues["selected_values"];
    temp["general"]["coreCompetenciesContentForSelectedRole"] =
      coreCompetenciesContentForSelectedRole;
    temp["general"]["valueContent"] = valueContent;

    console.log(temp);
    setGenerateQuestionData(temp);
    generateQuestionsHTMLFile(temp);
    saveAnalytics(enQuestionsDict["questions"]);
    console.log(coreCompetenciesContentForSelectedRole);
  };

  return (
    <div>
      <NavBarAuth
        userInfoAdmin={userInfoAdmin}
        resourceFileNamesList={resourceFileNamesList}
        resourceFileURLsList={resourceFileURLsList}
        allResourcesDictList={allResourcesDictList}
      />

      <div className="container">
        <div id="questions">
          <h2 className="section-title">
            {t("REVIEW AND SELECT YOUR QUESTIONS")}
          </h2>

          <b>
            <div className="instructions">
              <p>
                <span>
                  Select a total of 2-8 questions that are most relevant to the
                  role:
                </span>
              </p>
            </div>
          </b>
          {/*{alertInfo.open && <SendAlert alertMessage={alertInfo.alertMessage} variant={alertInfo.variant}/>}*/}

          <button
            type="button"
            className={
              toggleRecommeneded
                ? "btn btn-success btn-sm select_recommended_true"
                : "btn btn-success btn-sm select_recommended"
            }
            onClick={handleClickRecommeneded}
          >
            {t("Highlight Recommended Questions")}
          </button>
          <br />
          <br />
          <br />
          <h5 className="section-title">{t("Values")}</h5>
          <form onSubmit={handleSubmit(handleSubmission)}>
            {Array.from(
              Object.getOwnPropertyNames(valueQuestions),
              (value, valueIndex) => {
                return (
                  <div>
                    <div className="heading">
                      <p>{t(value)}</p>
                    </div>
                    <div className="heading_desc">
                      <p>{t(valueContent[value])}</p>
                    </div>
                    {Array.from(
                      Object.getOwnPropertyNames(valueQuestions[value]),
                      (questionType, questionTypeIndex) => {
                        return (
                          <div>
                            {Array.from(
                              Object.values(
                                valueQuestions[value][questionType]
                              ),
                              (question, questionIndex) => {
                                return (
                                  <label>
                                    <input
                                      data-question-type={questionType}
                                      data-question-en={
                                        valueQuestionsEN[value][questionType][
                                          questionIndex
                                        ]
                                      }
                                      id="question"
                                      value={question}
                                      name="question"
                                      {...register(value, {
                                        onChange: (e) =>
                                          handleQuestionChange(e),
                                      })}
                                      key={
                                        "Values" +
                                        valueIndex +
                                        "_" +
                                        questionTypeIndex +
                                        "_" +
                                        questionIndex
                                      }
                                      type="checkbox"
                                    />
                                    {question}
                                  </label>
                                );
                              }
                            )}
                          </div>
                        );
                      }
                    )}
                  </div>
                );
              }
            )}

            <br />
            <hr />
            <br />

            <h5 className="section-title">{t("Competencies")}</h5>

            {Array.from(
              Object.getOwnPropertyNames(coreCompQuestions),
              (coreComp, coreCompIndex) => {
                return (
                  <div>
                    <div className="heading">
                      <p>
                        <img
                          className="core_comp_logos"
                          src={
                            "img/ppt_logos/" +
                            coreComp
                              .toLowerCase()
                              .replace(" & ", "_and_")
                              .replace(/ /g, "_") +
                            ".png"
                          }
                        />
                        {t(coreComp)}
                      </p>
                    </div>
                    <div className="heading_desc">
                      <p>
                        {t(coreCompetenciesContentForSelectedRole[coreComp])}
                      </p>
                    </div>
                    {Array.from(
                      Object.getOwnPropertyNames(coreCompQuestions[coreComp]),
                      (questionType, questionTypeIndex) => {
                        return (
                          <label>
                            {Array.from(
                              Object.values(
                                coreCompQuestions[coreComp][questionType]
                              ),
                              (question, questionIndex) => {
                                return (
                                  <label>
                                    <input
                                      data-question-type={questionType}
                                      data-question-en={
                                        coreCompQuestionsEN[coreComp][
                                          questionType
                                        ][questionIndex]
                                      }
                                      id="question"
                                      value={question}
                                      name="question"
                                      {...register(coreComp, {
                                        onChange: (e) =>
                                          handleQuestionChange(e),
                                      })}
                                      key={
                                        "Competencies" +
                                        coreCompIndex +
                                        "_" +
                                        questionTypeIndex +
                                        "_" +
                                        questionIndex
                                      }
                                      type="checkbox"
                                    />
                                    {question}
                                  </label>
                                );
                              }
                            )}
                          </label>
                        );
                      }
                    )}
                  </div>
                );
              }
            )}

            <br />
            <br />

            {alertInfo.open && (
              <SendAlert
                alertMessage={alertInfo.alertMessage}
                variant={alertInfo.variant}
              />
            )}

            <Modal show={modalState}>
              <Modal.Header>
                <h5 className="modal-title">
                  {t(
                    "You're one step closer to accessing your Interview Guide!"
                  )}
                </h5>

                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closeModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Header>
              <div className="modal-body">
                {loading && (
                  <Spinner animation="border" role="status" variant="danger">
                    <span className="visually-hidden"></span>
                  </Spinner>
                )}
                <h6 key="By clicking download or print, you agree to the following guidelines:">
                  {t(
                    "By clicking download or print, you agree to the following guidelines:"
                  )}
                </h6>
                <br />

                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    {...register("modalRequirement", {
                      onChange: (e) => handleModalSelectionChange(e),
                    })}
                  />
                  <label className="form-check-label" for="exampleCheck1">
                    {t(
                      "I will ask these set of questions to all candidates interviewing for a particular position."
                    )}
                  </label>
                </div>

                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck2"
                    {...register("modalRequirement", {
                      onChange: (e) => handleModalSelectionChange(e),
                    })}
                  />
                  <label className="form-check-label" for="exampleCheck2">
                    {t("I will take notes of the candidate’s responses.")}
                  </label>
                </div>

                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck3"
                    {...register("modalRequirement", {
                      onChange: (e) => handleModalSelectionChange(e),
                    })}
                  />
                  <label className="form-check-label" for="exampleCheck3">
                    {t(
                      "I will use probing questions to understand the candidate’s Thinking, Action, and the Outcome of each example."
                    )}
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck4"
                    {...register("modalRequirement", {
                      onChange: (e) => handleModalSelectionChange(e),
                    })}
                  />
                  <label className="form-check-label" for="exampleCheck4">
                    {t(
                      "I will rate each candidate using the rating scale provided."
                    )}
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck5"
                    {...register("modalRequirement", {
                      onChange: (e) => handleModalSelectionChange(e),
                    })}
                  />
                  <label className="form-check-label" for="exampleCheck5">
                    {t(
                      "I will provide my Talent Partner a copy of the completed Interview Guide for record keeping."
                    )}
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck6"
                    {...register("modalRequirement", {
                      onChange: (e) => handleModalSelectionChange(e),
                    })}
                  />
                  <label className="form-check-label" for="exampleCheck6">
                    {t(
                      "I have read and understand the legal guidelines before conducting any interview."
                    )}
                  </label>
                </div>
                <br />
                <p className="modal_class">
                  {t("Read the Legal Considerations Guideline here in:")}
                </p>

                <a
                  target="_blank"
                  href="https://firebasestorage.googleapis.com/v0/b/sephoratoptalent.appspot.com/o/Legal%20Considerations%20-%20Before%20You%20Start%20Recruiting.pdf?alt=media&token=ab060cad-7def-40f2-9c4f-b08e9b9bd011"
                >
                  <p className="modal_class">{t("ENGLISH")}</p>
                </a>

                <p className="modal_class" key="or">
                  {t("or")}
                </p>

                <a
                  target="_blank"
                  href="https://firebasestorage.googleapis.com/v0/b/sephoratoptalent.appspot.com/o/Legal%20Considerations%20-%20Before%20You%20Start%20Interviewing_FR.pdf?alt=media&token=0470dd6b-de3b-4a99-931b-583f655602f1"
                >
                  <p className="modal_class">{t("FRENCH")}</p>
                </a>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm"
                  id="printPDF"
                  disabled={numberOfModalRequirementSelected < 6}
                  onClick={printHTMLFile}
                >
                  {t("Print")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm"
                  id="download_pdf"
                  disabled={numberOfModalRequirementSelected < 6}
                  onClick={downloadHTMLFile}
                >
                  {t("Download")}
                </button>
              </div>
            </Modal>

            <div id="page_buttons">
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm btn-space"
                onClick={Previous}
              >
                {t("Back")}
              </button>
              <button
                className="btn btn-outline-secondary btn-sm btn-space"
                type="submit"
                disabled={
                  numberOfQuestionsSelected < 2 || numberOfQuestionsSelected > 8
                }
              >
                {t("Generate Interview Guide")}
              </button>
              {/* type="button" */}
            </div>
          </form>
        </div>

        <StepFooter
          formValues={formValues}
          currentSelectionPage="selected_questions"
        />
      </div>
    </div>
  );
};

export default Questions;
