import React from 'react'
import { Link, useNavigate } from "react-router-dom"
import { auth } from '../../Helper/firebase';

const Temp = ({action}) => {

	let navigate = useNavigate();

	function handleAction() {
		if (action=='logout'){
			auth.signOut().then(()=>{
				navigate("/", { replace: true });
			})
		}
	}

	return (
		<div>
			{handleAction()}
		</div>
	)
}

export default Temp