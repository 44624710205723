import React, {useState, useEffect} from 'react'
import {handleResetPassword} from '../../Helper/EmailHandler/auth_handle_reset_password';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import { auth } from '../../Helper/firebase';
import SendAlert from '../General/SendAlert';
import { useTranslation } from 'react-i18next';

const UpdatePassword = () => {

	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [alertInfo, setAlertInfo] = useState({open:false, alertMessage:'', variant:''});
	const [passwordMatchingInfo, setPasswordMatchingInfo] = useState({message:'not matching', textColor:'red', valid:false});
	const { t, i18n } = useTranslation()

	let navigate = useNavigate();
	const {state} = useLocation();
	const { actionCode } = state; // Read values passed on state


	useEffect(() => {
    	validatePassword()
	}, [password, confirmPassword]);

    useEffect(() => {
    	setPasswordMatchingInfo({message:t("not matching"), textColor:'red', valid:false})
	}, []);


	function validateForm() {
    	return password.length > 0 && confirmPassword.length > 0 && passwordMatchingInfo.valid;
	}

	
	function validatePassword() {
	    if(password.length > 0 && confirmPassword.length > 0){
	        if (password==confirmPassword){
	          setPasswordMatchingInfo({message:t("matching"), textColor:'green', valid:true})
	        } else {
	          setPasswordMatchingInfo({message:t("not matching"), textColor:'red', valid:false})
	        }
	    }
	}


	function resetPassword() {

		verifyPasswordResetCode(auth, actionCode).then((email) => {

		    // Save the new password.
		    confirmPasswordReset(auth, actionCode, password)
		    .then((resp) => {
		    	handleResponse("password updated")

		    	setTimeout(function () {
		    		navigate("/login?email="+email, { replace: true })
		    	}, 4000)
		    }).catch((error) => {
		    	handleResponse(error.code)
		    });
		}).catch((error) => {
			handleResponse(error.code)
		});
	}


	function handleSubmit(event) {
	    // close alert if open
	    setAlertInfo({open:false, alertMessage:'', variant:''})
	    event.preventDefault()
	    resetPassword()
  	}


	function handleResponse(response) {
	    // add more error code conditions
	    console.log(response)

	    if (response=="password updated"){
	        setAlertInfo({open:true, alertMessage:t("Your password has been successfully changed"), variant:'success'})
	      }
	    else {
	        setAlertInfo({open:true, alertMessage:t("There was a problem. Please reach out to admin."), variant:'danger'})
	      }
	  }
  

	return (
		<div>
	      <div class="top-container">
	        <Link to={'/'}>
	          <img src="img/sephora_github_proj/logo.png" alt="Sephora"></img>
	        </Link>
	      </div>
			<div className="public_form">
		      <div className="container-md">          
		        <p>{t("Forgot Password")}</p>
		      </div>
		      <div className="container-sm">
		        {alertInfo.open && <SendAlert alertMessage={alertInfo.alertMessage} variant={alertInfo.variant}/>}
		        <form className="form-signin" onSubmit={handleSubmit}>	           
		            <input
		              className="form-control"
		              type="password"
		              value={password}
		              onChange={(e) => setPassword(e.target.value)}
		              placeholder={t("Password")}
		              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" 
		              onInvalid={e => e.target.setCustomValidity(t("Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"))}
		              onInput={e => e.target.setCustomValidity('')}
		            />
		            <br />
		            <input
		              className="form-control"
		              type="password"
		              value={confirmPassword}
		              onChange={(e) => setConfirmPassword(e.target.value)}
		              placeholder={t("Confirm Password")}
		              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" 
		              onInvalid={e => e.target.setCustomValidity(t("Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"))}
		              onInput={e => e.target.setCustomValidity('')}
		            />
		            <br />

		            <span style={{color:passwordMatchingInfo.textColor}}>{passwordMatchingInfo.message}</span>
		            <br />

		            <button 
		              className="btn btn-outline-secondary forgot-password-button"
		              disabled={!validateForm()}
		            >
		              {t("Confirm")}
		            </button>        
		        </form>
		      </div>
	    	</div>
		</div>
	)
}

export default UpdatePassword