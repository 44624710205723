import React, { useEffect, useState, useMemo, useContext } from "react";
import { useForm } from "react-hook-form";
import { useAuthState } from "react-firebase-hooks/auth";
import { isLogin, logout, auth, db } from "../../../Helper/firebase";
import { useTranslation } from "react-i18next";
import { userInfoContext } from "../../Authentication/userInfoContext";
import { getDatabase, ref, onValue } from "firebase/database";
import { useList } from "react-firebase-hooks/database";
import StepFooter from "./StepFooter/StepFooter";
import i18next from "i18next";
import SendAlert from "./../../General/SendAlert";
import NavBarAuth from "./NavBarAuth";

const CoreCompetencies = ({
  prevStep,
  nextStep,
  handleChangeArray,
  formValues,
  coreCompetenciesContentForSelectedRole,
  roleType,
  getCoreCompQuestions,
  valueQuestions,
  userInfoAdmin,
  resourceFileNamesList,
  resourceFileURLsList,
  allResourcesDictList,
}) => {
  const [boxState, setBoxState] = useState([]);
  const [coreComp, setCoreComp] = useState({});
  const [alertInfo, setAlertInfo] = useState({
    open: false,
    alertMessage: "",
    variant: "",
  });

  const { register, handleSubmit, errors, reset } = useForm();
  const { t, i18n } = useTranslation();
  const lang = i18next.language.substr(0, 2);

  useEffect(() => {
    var coreCompList = Object.keys(coreCompetenciesContentForSelectedRole);

    var index = coreCompList.indexOf("Values Uniqueness & Differences");

    if (index !== -1) {
      coreCompList.splice(index, 1).sort();
      coreCompList.unshift("Values Uniqueness & Differences");
    }

    setCoreComp(coreCompList);

    var obj = createFreshObjectForBoxState(
      coreCompetenciesContentForSelectedRole
    );

    // if there is values in form values, update obj state of boxes to be rendered so that selection is shown
    if (formValues.selected_core_competencies != "") {
      obj = updateObjectWithPreviousSelections(obj);
    }

    setBoxState(obj);
  }, [coreCompetenciesContentForSelectedRole]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Previous = (e) => {
    e.preventDefault();
    prevStep();

    // close the alert
    handleAlert([]);
  };

  const Continue = (e) => {
    getCoreCompQuestions();
    e.preventDefault();
    console.log(formValues);
    if (lang == "fr") {
      setTimeout(nextStep, 1000);
    } else {
      setTimeout(nextStep, 500);
    }
  };

  function getAllSelections(obj) {
    var selections = [];
    var val_keys = Object.keys(coreCompetenciesContentForSelectedRole);

    Array.from(Object.keys(obj.selected), (e, i) => {
      var val = obj.selected[val_keys[i]];

      if (val == true) {
        selections.push(val_keys[i]);
      }
    });

    return selections;
  }

  function update(event) {
    const selectedCoreComp = event.target.value;

    var obj = boxState;

    if (event.target.checked && !boxState.selected[selectedCoreComp]) {
      obj.selected[selectedCoreComp] = true;
    } else if (boxState.selected[selectedCoreComp]) {
      obj.selected[selectedCoreComp] = false;
    }

    setBoxState(obj);

    var allSelectedCoreComp = getAllSelections(obj);
    handleChangeArray(event, allSelectedCoreComp);
    handleAlert(allSelectedCoreComp);
  }

  function handleAlert(selectionsArr) {
    var count = selectionsArr.length;

    if (count > 3) {
      setAlertInfo({
        open: true,
        alertMessage: t(
          "Select a minimum of 1 competency up to a maximum of 3."
        ),
        variant: "danger",
      });
    } else {
      setAlertInfo({ open: false, alertMessage: "", variant: "" });
    }
  }

  function createFreshObjectForBoxState(coreCompetenciesContent) {
    var valueKey = Object.keys(coreCompetenciesContent);

    var obj = { selected: {} };

    valueKey.map((item, i) => (obj.selected[item] = {}));
    valueKey.map((item, i) => (obj.selected[item] = false));

    return obj;
  }

  function updateObjectWithPreviousSelections(obj) {
    const selectedCoreComp = formValues.selected_core_competencies;

    if (selectedCoreComp != "") {
      {
        Array.from(selectedCoreComp, (e, i) => {
          obj.selected[selectedCoreComp[i]] = true;
        });
      }

      var allSelectedCoreComp = getAllSelections(obj);
      handleAlert(allSelectedCoreComp);
    }

    return obj;
  }

  return (
    <div>
      <NavBarAuth
        userInfoAdmin={userInfoAdmin}
        resourceFileNamesList={resourceFileNamesList}
        resourceFileURLsList={resourceFileURLsList}
        allResourcesDictList={allResourcesDictList}
      />

      <div className="container">
        <h2 className="section-title">
          {t("Leading the Sephora Way Competencies")}
        </h2>
        <br />
        <p className="selection-limit">
          {t(
            "Pick 1 to 3 competencies that you want to assess in your interview:"
          )}
        </p>

        <div className="row" id="generate_core_competency">
          {Array.from(coreComp, (e, i) => {
            return (
              <div key={i} className="col-md-4">
                <label
                  className={
                    boxState.selected[coreComp[i]]
                      ? "btn btn-outline-info " +
                        coreComp[i]
                          .toLowerCase()
                          .replace(" ", "_")
                          .replace(" & ", "_and_") +
                        " active"
                      : "btn btn-outline-info " +
                        coreComp[i]
                          .toLowerCase()
                          .replace(" ", "_")
                          .replace(" & ", "_and_")
                  }
                >
                  <input
                    {...register("selected_core_competencies", {
                      onChange: (e) => update(e),
                    })}
                    type="checkbox"
                    name="selected_core_competencies"
                    value={coreComp[i]}
                  />
                  <div class="option_heading">
                    <p>
                      <img
                        class="core_comp_logos"
                        src={
                          "img/ppt_logos/" +
                          coreComp[i]
                            .toLowerCase()
                            .replace(" & ", "_and_")
                            .replace(/ /g, "_") +
                          ".png"
                        }
                      />
                      {t(coreComp[i])}
                    </p>
                  </div>
                  <div class="description">
                    {t(coreCompetenciesContentForSelectedRole[coreComp[i]])}
                  </div>
                </label>
              </div>
            );
          })}
        </div>

        <br />

        {alertInfo.open && (
          <SendAlert
            alertMessage={alertInfo.alertMessage}
            variant={alertInfo.variant}
          />
        )}
        <div id="page_buttons">
          <button
            type="button"
            className="btn btn-outline-secondary btn-sm btn-space"
            onClick={Previous}
          >
            {t("Back")}
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary btn-sm btn-space"
            onClick={Continue}
            disabled={
              formValues.selected_core_competencies.length > 3 ||
              formValues.selected_core_competencies.length == 0
            }
          >
            {t("Next")}
          </button>
        </div>

        <StepFooter
          formValues={formValues}
          currentSelectionPage="selected_core_competencies"
        />
      </div>
    </div>
  );
};

export default CoreCompetencies;
