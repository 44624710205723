import React, {useState, useEffect} from 'react'
import { storage, storage_ref } from "../../../../Helper/firebase";
import SendAlert from '../../../General/SendAlert';
import Spinner from 'react-bootstrap/Spinner';
import { useForm } from 'react-hook-form';
import { listAll, getMetadata, deleteObject } from "firebase/storage";

const DeleteResourcesComponent = ({work_location, file_upload_loading}) => {
	const [loading, setLoading] = useState(false);
	const { register, handleSubmit, errors, reset } = useForm()
	const [checkboxesForDeletion, setCheckboxesForDeletion] = useState([])
	const resourceRef = storage_ref(storage, work_location+'/Resources');


	useEffect(()=>{
		pull_files_names_from_storage(work_location)
	},[file_upload_loading, loading, work_location])
	

	const pull_files_names_from_storage = async (work_location) => {
    	
    	var metaDataResourceListPromise = []
    	var fileNameList = []
    	
		listAll(resourceRef)
		.then((res) => {				
			for (var i = 0; i <= res.items.length-1; i++) {	
				var itemRef = res.items[i]
				metaDataResourceListPromise.push(getMetadata(itemRef))
			}						
			createCheckboxes(metaDataResourceListPromise)
		})			
	}


	function createCheckboxes(metaDataResourceListPromise){
		
		var checkBoxes = []
		var fileName
		var filePath

		Promise.all(metaDataResourceListPromise)
		.then((metaDataResources) => {

  			for (var i = 0; i <= metaDataResources.length-1; i++) {	
  				fileName = metaDataResources[i].name
  				filePath = metaDataResources[i].fullPath

		      	checkBoxes.push(
			        <div>
			            <label>
	                        <input {...register("resource_file")} type="checkbox" name="resource_file" value={filePath}/> {fileName} 
	                    </label>
			        </div>
	        	)
	        }	       
	    	setCheckboxesForDeletion(checkBoxes)
		})
		
	}
	

	function handleSubmitGetRequest(data) {
		reset()
		console.log('called')
		setLoading(true)		

		// Delete the file
		var resourcesSelectedArray = data.resource_file
		var resourcePath;
		var resourceRef;
		var PromiseArr = []
		
		console.log(resourcesSelectedArray.resource_file)

		// if only 1 selection is made, convert it to list from string
		if (typeof(resourcesSelectedArray)=='string'){
			resourcesSelectedArray=[resourcesSelectedArray]
		}

		for (var i = 0; i <= resourcesSelectedArray.length-1; i++) {
			resourcePath = resourcesSelectedArray[i]
			resourceRef = storage_ref(storage, resourcePath);
			PromiseArr.push(deleteObject(resourceRef))			
		}

		Promise.all(PromiseArr).then(
			setLoading(false)
		)

		
	  }


	return (
		<div>
			<br/>
			<br/>
			<br/>
						

			<h2>Delete Files:
				{loading &&
			        <Spinner animation="border" role="status" variant="danger">
	  					<span className="visually-hidden"></span>
					</Spinner>
				}
			</h2>
			<form id= 'deletedResources'method="POST" onSubmit={handleSubmit(handleSubmitGetRequest)} enctype="multipart/form-data" content-type="multipart/form-data">
			  {checkboxesForDeletion}

			  <button className="btn btn-outline-secondary btn-sm btn-space" style={{minWidth: '5rem', padding: '0', fontSize: '1rem'}} type="submit">Submit</button>
			</form>

		</div>
	)
}

export default DeleteResourcesComponent