import React from 'react'
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next';

import {Suspense, useEffect, useMemo } from 'react';
import { Routes, Route, BrowserRouter, Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import HandleEmailAction from '../../Component/Authentication/handleEmailAction';

const LandingPage = ({redirect}) => {
	const { t, i18n } = useTranslation()

	return (
		<div id='landing-page-adjustments'>
			<HandleEmailAction />	
	        <h1><strong>{t("Digital")}</strong> <span>{t("INTERVIEW GUIDE")}</span></h1>
	        
	        <h2>{t("Assessing Top Talent")}</h2>
	        
	        <div class="col-xs-6">
	        	<Link to="/login"><button type="button" class="lang btn btn-outline-secondary">{t("Login")}</button></Link>
	        </div>

	        <br />
	        <br />
	                
	        <figure>
	            <img height="100" src="img/landing_page_leaf.png"/>
	            <figcaption>{t("#WeAreSephora")}</figcaption>
	            <figcaption>{t("#EndlessEvolution")}</figcaption>
	        </figure>

	        <p class="bottomright">
	            <a>EN</a>|<a>FR</a>
	        </p>
		</div>
	)
}

export default LandingPage