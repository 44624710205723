import React, { useEffect, useState, useMemo, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { auth, browser_local_persistence } from "../../Helper/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import SendAlert from '../General/SendAlert';
import { firebase } from '../../Helper/firebase';
import { signInWithEmailAndPassword } from "firebase/auth";
import { useTranslation } from 'react-i18next';

function Login({fill_in_email}) {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alertInfo, setAlertInfo] = useState({open:false, alertMessage:'', variant:''});
  const { t, i18n } = useTranslation()
  const [user, loading, error] = useAuthState(auth);


  let navigate = useNavigate();
  
 
  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }


  let query = useQuery()

  useEffect(()=>{
    var email_param = query.get("email")
    var emailVerified = query.get("emailVerified")

    if (email_param){
      setEmail(email_param)
    }
    else if (emailVerified=='success'){
      setAlertInfo({open:true, alertMessage:t("Your email has been verified"), variant:'success'})
    }
    else if (emailVerified=='fail'){
      setAlertInfo({open:true, alertMessage:t("Please verify your email to access the tool. If verification link has expired, please go to sephoratoptalent.com/verify"), variant:'danger'})
    }     
  }, [])



  function validateForm() {    
    return email.length > 0 && password.length > 0 && email.endsWith("@sephora.com");
  }


  function handleSubmit(event) {
    // close alert if open
    setAlertInfo({open:false, alertMessage:'', variant:''})
    event.preventDefault()
    login(email, password)
  }


  function handleResponse(response) {
    // console.log(response)
    // add more error code conditions

    if (response=="auth/user-not-found"){
        setAlertInfo({open:true, alertMessage:t("Could not login. Please check and try again."), variant:'danger'})
      }

    else if (response=="email not verified"){
        setAlertInfo({open:true, alertMessage:t("Please verify your email to access the tool. If verification link has expired, please go to sephoratoptalent.com/verify"), variant:'danger'})
      }

    else {
        setAlertInfo({open:true, alertMessage:t("There was a problem. Please reach out to admin."), variant:'danger'})
      }
  }
  

  async function login(email, password){

    browser_local_persistence.then(() => {
        if (email.endsWith("@sephora.com")){
          signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
              var emailVerified = userCredential.user.emailVerified
              if(emailVerified){
                // get_user_info_from_database().then(()=>{
                navigate("/generate-interview-guide", { replace: true })
                // })
                
              } else {
                handleResponse('email not verified')
                // auth.signOut()
              }
            })
            .catch((error) => {        
              handleResponse(error.code)
            })
          }
        })}



  return (
    <div>
      <div class="top-container">
        <Link to={'/'}>
          <img src="img/sephora_github_proj/logo.png" alt="Sephora"></img>
        </Link>
      </div>

      <div className="public_form">
        <div className="container-md">          
          <p>{t("Login to create your Interview Guide")}</p>
        </div>
        <div className="container-sm">        
          {alertInfo.open && <SendAlert alertMessage={alertInfo.alertMessage} variant={alertInfo.variant}/>}
          <form className="form-signin" onSubmit={handleSubmit}>
              <input
                name='email'
                className="form-control"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t("Work Email Address")}
                required="required" 
                pattern=".+@sephora.com" 
                onInvalid={e => e.target.setCustomValidity(t("Enter your @sephora.com email address"))}
                onInput={e => e.target.setCustomValidity('')}
              />
              <br />
              <input
                className="form-control"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={t("Password")}
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" 
                onInvalid={e => e.target.setCustomValidity(t("Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"))}
                onInput={e => e.target.setCustomValidity('')}
              />
              <br />
              <button 
                className="btn btn-outline-secondary login-button"
                disabled={!email}
                // disabled={!validateForm()}
              >
                {t("Login")}
              </button>        
                <p>
                  <Link 
                    to="/forgot-password"
                    className="go-left"
                  >{t("Forgot Password")}
                  </Link>
                </p>
          </form>
        </div>
      </div>
    </div>
  );
}
export default Login;