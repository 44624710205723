import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../Helper/firebase";
import { sendResetPasswordEmail } from "../../Helper/APIService";
import { useCreateUserWithEmailAndPassword } from "react-firebase-hooks/auth";
import SendAlert from '../General/SendAlert';
import { logoutPreviousUser, firebase, logout } from '../../Helper/firebase';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useTranslation } from 'react-i18next';

const ResetPasswordRequest = () => {

	const [email, setEmail] = useState("");
	const [alertInfo, setAlertInfo] = useState({open:false, alertMessage:'', variant:''});
	const { t, i18n } = useTranslation()

	let navigate = useNavigate();
	
	function validateForm() {    
    	return email.length > 0 && email.endsWith("@sephora.com")    
  	}

  	const sendEmailToResetPassword = async () => {
    	sendResetPasswordEmail({email})
    	.then((resp) => {
    		handleResponse(resp.message)
    	})
    	.catch((error) => {
    		handleResponse(error.code)
    	})
  	}
  

	function handleSubmit(event) {

		// close alert if open
		event.preventDefault()
		setAlertInfo({open:false, alertMessage:'', variant:''})
		sendEmailToResetPassword()  
	}


	function handleResponse(response) {
    	// add more error code conditions

	    if (response=="email not sent"){
	        setAlertInfo({open:true, alertMessage:t("There was a problem. Please reach out to admin."), variant:'danger'})
		}
	    else if (response=="email sent successfully"){
	        setAlertInfo({open:true, alertMessage:t("Please check your email."), variant:'success'})
		}
	    else {
	        setAlertInfo({open:true, alertMessage:t("There was a problem. Please reach out to admin."), variant:'danger'})
		}
  	}


	return (
		<div>
	      <div class="top-container">
	        <Link to={'/'}>
	          <img src="img/sephora_github_proj/logo.png" alt="Sephora"></img>
	        </Link>
	      </div>
			<div className="public_form">
				<div className="container-md">          
		        	<p>{t("Send Reset Password Request")}</p>
				</div>
				<div className="container-sm">
					{alertInfo.open && <SendAlert alertMessage={alertInfo.alertMessage} variant={alertInfo.variant}/>}
					<form className="form-signin" onSubmit={handleSubmit}>
						<input
							name='email'
							className="form-control"
							type="text"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							placeholder={t("Work Email Address")}
							required="required" 
							pattern=".+@sephora.com" 
							onInvalid={e => e.target.setCustomValidity(t("Enter your @sephora.com email address"))}
							onInput={e => e.target.setCustomValidity('')}
						/>
						<br />					

						<button 
						className="btn btn-outline-secondary forgot-password-button"
						// disabled={!email}
						disabled={!validateForm()}
						>
						{t("Confirm")}
						</button>        
					</form>
				</div>
			</div>
		</div>
	)
}

export default ResetPasswordRequest