import React, { Suspense, useEffect, useState, useMemo, useContext } from "react";
import { useForm } from 'react-hook-form';
import { useAuthState } from "react-firebase-hooks/auth";
import { isLogin, logout, auth as fb_auth, db } from '../../../Helper/firebase';
import { useTranslation, Trans } from 'react-i18next';
import {userInfoContext} from '../../Authentication/userInfoContext';
import { getDatabase, ref as database_ref, onValue} from "firebase/database";
import { useList } from 'react-firebase-hooks/database';
import StepFooter from './StepFooter/StepFooter';
import NavBarAuth from './NavBarAuth';
import { Link, useNavigate } from "react-router-dom";

const Locations = ({nextStep, handleChange, formValues, locationAccess, userInfoAdmin, resourceFileNamesList, getResourceInfoRender, resourceFileURLsList, allResourcesDictList}) => {
  
	const [location, setLocation] = useState([])
	const [boxState, setBoxState] = useState([])
	
	const { register, handleSubmit, errors, reset } = useForm()
	const { t, i18n } = useTranslation()


	var locationAccessObj = {
		"Manager":['Beauty Advisor'],
		"Store Director":['Field'],
		"District Manager":['Field'],
		"Regional Director":['Field'],
		"Individual Contributor":['FSC'],
		"Sr Manager or Manager":['FSC'],
		"Sr Director or Director":['FSC'],
		"Vice President":['FSC', 'Field'],
	}

	useEffect(() => {
  		window.scrollTo(0, 0)

	}, [userInfoAdmin])


	useEffect(() => {
  		// console.log(resourceFileNamesList)
	}, [resourceFileNamesList])
		
	useEffect(() => {	
		setLocation(locationAccess)
		var obj = createFreshObjectForBoxState(locationAccess)
		
		// if there is values in form values, update obj state of boxes to be rendered so that selection is shown
		if (formValues.selected_location!=''){
			obj = updateObjectWithPreviousSelections(obj)
		}

		setBoxState(obj)
		// console.log(locationAccess)
  }, [locationAccess]);


	// for continue event listener
	const Continue = e => {
		e.preventDefault()
		nextStep()
	}
	

	function update(event){
		const selectedVal = event.target.value
		const selectedLocation = event.target.value				

		if(event.target.checked){
			updateBoxState(selectedLocation)
			handleChange(event)
		}	

		getResourceInfoRender(event.target.value)
	}


	function updateBoxState (selectedLocation){
		var obj = createFreshObjectForBoxState(location)
		obj.selected[selectedLocation]=true
		setBoxState(obj)
	}


	function createFreshObjectForBoxState (locationAccessArr){

		var obj = {selected:{}}
		locationAccessArr.map((item, i)=>obj.selected[item]={})
		locationAccessArr.map((item, i)=>obj.selected[item]=false)

		return obj
	}


	function updateObjectWithPreviousSelections(obj){
		const selectedLocation = formValues.selected_location

		if(selectedLocation!=''){
			obj.selected[selectedLocation]=true
		}

		return obj
	}




	return (

		<div>
			<NavBarAuth userInfoAdmin={userInfoAdmin} resourceFileNamesList={resourceFileNamesList} resourceFileURLsList={resourceFileURLsList} allResourcesDictList={allResourcesDictList}/>

	    	<div className="container">
			<h2 className="lang section-title">{t("I'M HIRING AT:")}</h2>

		    <div className="row" id='generate_locations'>
		      	{Array.from(location, (e, i) => {
		      		return (
		      			<div key = {i} className="col-md-3" >
									<label className={boxState.selected[location[i]]?"btn btn-outline-dark active": "btn btn-outline-dark"}>
										<div className="centerd">
											<input {...register("selected_location", {onChange: (e) => update(e)})} type="radio" name="selected_location" value={location[i]}/> <Trans>{location[i]}</Trans> 
										</div>
									</label>
				      	</div>
			      	)
		      	})}      	            
	    	</div>

			<div id="page_buttons">
				<button type="button" className="lang btn btn-outline-secondary btn-sm btn-space" onClick={ Continue } disabled={!formValues.selected_location!=''}>{t("Next")}</button>
			</div>
			
			<StepFooter formValues={formValues} currentSelectionPage='selected_location'/>	

			</div>	
		</div>	
	)
}

export default Locations