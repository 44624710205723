// import i18next from 'i18next';
// import HttpApi from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
// import { initReactI18next } from 'react-i18next';

// i18next
//   .use(HttpApi)
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     supportedLngs: ['en', 'fr'],
//     fallbackLng: 'en',
//     // debug: false,
//     // Options for language detector
//     detection: {
//       order: ['navigator'],
//       // caches: ['cookie'],
//       // caches: ['localStorage', 'cookie'],
//     },
//     // react: { useSuspense: false },
//     backend: {
//       loadPath: '/locales/{{lng}}/translation.json',
//     },
//     // react: { useSuspense: false }
//   })
// export default i18next;

// import i18n from "i18next";
// import detector from "i18next-browser-languagedetector";
// import backend from "i18next-xhr-backend";
// import { initReactI18next } from "react-i18next";

// // translations are already at
// // '../public/locales/en/translation.json'
// // which is the default for the xhr backend to load from

// i18n
//   .use(detector)
//   .use(backend)
//   .use(initReactI18next) // passes i18n down to react-i18next
//   .init({
//     supportedLngs: ['en', 'fr'],
//     fallbackLng: "en", // use en if detected lng is not available

//     keySeparator: false, // we do not use keys in form messages.welcome

//     interpolation: {
//       escapeValue: false // react already safes from xss
//     }
//   });

// export default i18n;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  // en: {
  //   translation: {
  //     "wlcome": "wlcomesss",
  //   }
  // },
  fr: {
    translation: {
      "Send Reset Password Request":
        "Votre demande de réinitialisation de mot de passe",
      "Forgot Password": "Mot de passe oublié",
      "Send Email Verification Request":
        "Envoyez une demande de vérification de courriel",
      Confirm: "Confirmer",
      "Select a total of 4-8 questions that are most relevant to the role:":
        "Sélectionnez un total de 4 à 8 questions les plus pertinentes pour le poste:",
      "Pick 1 to 3 competencies that you want to assess in your interview:":
        "Choisissez une à trois compétence que vous souhaitez évaluer lors de l'entrevue:",
      "Pick between 1 to 3 values that you want to assess in your interview:":
        "Choisissez une à trois valeurs que vous souhaitez évaluer lors de l'entrevue:",
      "Read the Legal Considerations Guideline here in":
        "N’oubliez pas de lire les remarques juridiques ici:",
      FRENCH: "en français",
      ENGLISH: "en anglais",
      or: "ou",
      "Login to create your Interview Guide":
        "Vous connecter pour créer le guide d'entrevue",

      // Alert Message
      "User already registered. Please reach out to admin if you have problems logging in.":
        "Utilisateur déjà inscrit. Veuillez joindre un administrateur si vous avez des problèmes à ouvrir une session.",
      "Your profile could not be found. Please reach out to admin.":
        "Nous n’avons pas pu trouver votre profil. Veuillez joindre un administrateur.",
      "Your email has been verified": "Votre adresse courriel a été validée.",
      "Could not login. Please check and try again.":
        "Impossible d’ouvrir une session. Veuillez vérifier et essayer de nouveau.",
      "Please verify your email to access the tool. If verification link has expired, please go to sephoratoptalent.com/verify":
        "Veuillez consulter votre boîte de courriel pour accéder à l’outil.   Si le lien de validation a expiré, rendez-vous ici sephoratoptalent.com/verify",
      "Your password has been successfully changed":
        "Votre mot de passe a été changé avec succès.",
      "Email does not exist": "L’adresse courriel n’existe pas",
      "Please check your email.": "Veuillez consulter votre boîte de courriel",
      "There was a problem. Please reach out to admin.":
        "Un problème est survenu. Veuillez joindre un administrateur",

      // Home Page
      Interview: "GÉNÉRATEUR",
      "INTERVIEW GUIDE": "GUIDE D'ENTREVUE",
      "Assessing Top Talent": "Évaluer les meilleurs talents",
      Login: "Se Connecter",
      "#WeAreSephora": "#NousSommesSephora",
      "#EndlessEvolution": "#ÉvolutionConstante",

      // Login Page
      "Log in to the Digital Interview Question Generator Tool":
        "Connectez-vous à l'outil générateur de questions d'entrevue.",
      "Forgot password?": "Mot de passe oublié?",

      // Register Page
      Register: "S'inscrire",
      "Please complete the following information to register for an account":
        "Veuillez fournir les informations suivantes pour créer un compte",
      "Work Email Address": "Adresse courriel Sephora",
      password: "Mot de passe",
      Password: "Mot de passe",
      "Confirm Password": "Confirmer le mot de passe",
      "Password must be 8 characters or more.":
        "Le mot de passe doit contenir plus de 8 caractères", // where is this coming from? The translation is a bit different but can regardless cannot find the orig string

      // First Page - Choose Location
      "I'M HIRING AT:": "NOUS EMBAUCHONS",
      FSC: "Au CSO",
      Field: "En magasin",

      // Second Page - Choose candidate
      "I'M HIRING FOR:": "POSTE À COMBLER :",

      // FSC
      "Individual Contributor": "Contributeur individuel",
      "Manager/Sr. Manager": "Responsable principal et responsable",
      "Director/Sr. Director": "Directeur principal et directeur",
      "Vice President": "Vice-président",

      // Field
      "Beauty Advisor": "Conseiller beauté",
      Lead: "Chef d’équipe",
      "Loss Prevention": "Prévention des pertes",
      Manager: "Responsable de magasin",
      "Store Director": "Directeur de magasin",
      "District Manager": "Responsable de district",
      "Regional Director": "Directeur régional",

      // Third Page - Choose Values
      "OUR VALUES": "NOS VALEURS",
      "Select a minimum of 1 value to a maximum of 3 values.":
        "Sélectionnez une valeur au minimum et trois valeurs au maximum.",

      // Title
      Balance: "Équilibre",
      Expertise: "Expertise",
      Initiative: "Initiative",
      Innovation: "Innovation",
      Passion: "Passion",
      Respect: "Respect",
      Teamwork: "Travail d'équipe",

      // Defination
      "Demonstrating care for ourselves and each other":
        "Quand vient le temps de prendre soin de nous et des autres.",
      "With a growth mind-set, creating experiences that demonstrate our position as the beauty destination":
        "Avec une philosophie axée sur la progression, en créant des expériences qui démontrent notre position en tant que destination beauté.",
      "Taking ownership and holding ourselves accountable for driving business results":
        "En prenant ses responsabilités et en assumant le devoir de pousser les ventes.",
      "Taking risks to enhance the client experience":
        "En prenant des risques afin de rehausser l'expérience client.",
      "Putting our clients at the centre of everything we do":
        "En plaçant le client au cœur de toutes nos actions. ",
      "Fostering a sense of belonging for everyone.":
        "En inspirant un sentiment d'appartenance pour tous.",
      "Working together to accomplish any task at hand":
        "En travaillant ensemble pour accomplir n'importe quelle tâche.",

      // Fourth Page - Core Competency Page
      "Select a minimum of 1 competency up to a maximum of 3.":
        "Sélectionnez une compétence au minimum et trois compétence au maximum.",

      // Title
      "Values Uniqueness & Differences":
        "Favorise l'unicité et les différences",
      "Agility & Manages Ambiguity": "Souplesse et gestion des ambiguïtés",
      "Client Focus": "Orientation vers la clientèle",
      Collaborates: "Collaboration",
      "Cultivates Creativity & Disruption": "Cultive la créativité",
      "Drives Results": "Obtention de résultats",
      Resilience: "Résilience",
      "Strategic Vision": "Vision stratégique",
      "Engages & Inspires": "S'engage et inspire",
      "Helps Others Grow": "Aide les autres à évoluer",

      // Defination
      "Valuing personal differences & cultures and  the perspectives they bring, leveraging  diversity of angles.":
        "Favorise les différences personnelles et culturelles ainsi que la vision que ces différences apportent, exploitant ses différences de point de vue.",
      "Making sense of complex or incomplete  information to solve problems – Able to  decide & act quickly and effectively  without having the “full picture” in hand.":
        "Trouve un sens aux informations incomplètes ou complexes pour résoudre les problèmes — Capable de décider et d'agir rapidement et efficacement sans avoir une « vision globale » disponible.",
      "Building strong client relationships & delivering client-centric solutions.":
        "Bâtir de solides relations avec la clientèle et offrir des solutions axées sur les clients.",
      "Interacting trustfully & openly with diverse groups of people, building relationships within & beyond the organization. Working collectively, highlighting wins, celebrating & crediting the team & individuals for successes.":
        "Interagit ouvertement et de façon confiante avec divers groupes de personnes, tisse des liens à l'intérieur et à l'extérieur de l'entreprise. Travaille de façon collaborative, met en valeur les bons coups, félicite et donne le mérite à l'équipe et aux personnes pour leurs succès.",
      "Challenge-driven, rethinking traditions, practices & rules, pushing boundaries & status quo. Not afraid of failure, thinking  “out of the box” and acting accordingly.":
        "Motivé par les défis, repense les façons de faire et les règles, repousse les obstacles et le statu quo. N'a pas peur de l'échec, sort des sentiers battus et agit en conséquence.",

      "Making good & timely decisions to take  the organization forward with a consistent commitment to meeting stretching  objectives & results, operating with energy & passion for the collective good.":
        "Prend de bonnes décisions et moment opportun pour amener l'entreprise plus loin. Engagement constant à rencontrer et dépasser les objectifs et les résultats. Agit avec énergie et passion pour le bien collectif.",
      "Rebounding from setbacks & adversity when facing difficult situations.":
        "Rebondir après un échec et faire face à l’adversité lorsque la situation est difficile.",
      "Seeing ahead to future possibilities & translating them into a compelling vision, & breakthrough strategies to pursue that vision":
        "Prévoir les possibilités futures et les traduire en stratégies inédites.",
      "Creating a climate where people are empowered & motivated to do their best to help the organization achieve its objectives.":
        "Créer un climat dans lequel les personnes ont à cœur d’aider au maximum l’organisation à atteindre ses objectifs.",
      "Creating a climate where people are encouraged to speak up and grow.  Understanding people’s strengths, needs & aspirations, pushing them to challenge  their own comfort zone & providing support to help them reach their potential.":
        "Créer un climat où les gens sont encouragés à parler et à évoluer. Comprendre les forces, les besoins et les aspirations des personnes et les pousser à dépasser leur propre zone de confort. Offrir du soutien pour les aider à atteindre leur potentiel maximum.",

      // Fifth Page - Question Generation
      "Leadership Expectations and Competency Framework":
        "ATTENTES À L'ÉGARD DES RESPONSABLES ET DE LEURS COMPÉTENCES",
      "Leading the Sephora Way Competencies":
        "Montrer La Voie Sephora Competences",

      "Pick at least one competency you want to assess for in your interview:":
        "Choisissez au moins une compétence que vous souhaitez évaluer lors de l'entrevue :",
      "REVIEW AND SELECT YOUR QUESTIONS":
        "RÉVISEZ ET SÉLECTIONNEZ VOS QUESTIONS",
      "Highlight Recommended Questions": "Soulignez les questions recommandées",
      "Choose between a total of 4-8 questions that are most relevant to the":
        "Choisissez de 4 à 8 questions qui sont les plus pertinentes pour le",
      role: "rôle:",
      values: "VALEURS",
      Competencies: "Compétences",
      competency: "compétence",
      "You’re one step closer to printing downloading your Interview Guide!":
        "Vous voilà presque prêt à imprimer votre guide d'entrevue!",
      "By clicking download or print, you agree to the following guidelines:":
        "En cliquant sur « télécharger » / « imprimer » , vous vous engagez à suivre les lignes directrices suivantes:",
      "I will ask these set of questions to all candidates interviewing for a particular position.":
        "Je poserai l'ensemble des questions à tous les candidats qui passent l'entrevue pour un poste en particulier.",
      "I will take notes of the candidate’s responses.":
        "Je noterai les réponses des candidats.",
      "I will use probing questions to understand the candidate’s Thinking, Action, and the Outcome of each example.":
        "Je poserai des questions approfondies pour comprendre la situation, les comportements et les résultats de chaque mise en situation.",
      "I will rate each candidate using the rating scale provided.":
        "J'évaluerai chaque candidat selon la cote de rendement fournie.",
      "I will provide my Talent Partner a copy of the completed Interview Guide for record keeping.":
        "Je ferai parvenir à mon partenaire talent une copie du guide d'entrevue complété pour les dossiers.",
      "I have read and understand the legal guidelines before conducting any interview.":
        "Avant de conduire toute entrevue, j'ai lu et je comprends toutes les directives légales.",

      Values: "VALEURS",
      "Generate Interview Guide": "Générer Un Guide Pour Les Entretiens",
      "You're one step closer to accessing your Interview Guide!":
        "Vous voilà presque prêt à imprimer votre guide d'entrevue!",
      "Read the Legal Considerations Guideline here in:":
        "N’oubliez pas de lire les remarques juridiques ici:",

      // General
      Resources: "Ressources",
      Admin: "Administration",
      Logout: "Se déconnecter",
      Print: "Imprimer",
      Download: "Télécharger",
      Back: "Précédent",
      Next: "Suivant",

      // OTHERS - PASTING ALL TO SAVE TIME BUT NEED TO BE ORGNAIZED
      "You have selected ": " vous avez sélectionné ",
      " more questions than needed.": "  plus de questions que nécessaire.",
      // temp
      empty: "empty fr",
      missing: "missing fr",
      "Generate Interview Guide": "Générer Un Guide Pour Les Entretiens",
      "Select a minimum of 1 competency up to a maximum of 4.":
        "Sélectionnez une compétence au minimum et quatre compétence au maximum.",
      matching: "Correspond",
      "not matching": "Ne correspond pas",
      "Select a minimum of 1 value to a maximum of 3 values.":
        "Sélectionnez une valeur au minimum et trois valeurs au maximum.",
      "Pick 1 to 4 competencies that you want to assess in your interview":
        "Choisissez une à quatre compétence que vous souhaitez évaluer lors de l'entrevue:",
      "Please select a maximum of ": "Veuillez choisir un maximum de ",
      "Please select a minimum of ": "Veuillez choisir un minimum de ",
      " questions.": " questions.",

      // General
      Next: "Suivant",
      "Download Interview Guide": "Télécharger le guide d'entrevue",
      "": "",
      "Enter your @sephora.com email address":
        "Veuillez inscrire votre adresse courriel @sephora.com",
      "Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters":
        "Doit contenir au moins un chiffre, une lettre majuscule et une minuscule ainsi qu’au moins huit autres caractères.",
      "Pick 1 value that you want to assess in your interview:":
        "Choisissez une valeur que vous souhaitez évaluer lors de l'entrevue :",
      "Pick 1-3 values you want to assess in your interview:":
        "Choisissez une à trois valeurs que vous souhaitez évaluer lors de l'entrevue :",

      // Choose Location
      FSC: "Au CSO",
      Field: "En magasin",

      // Choose candidate - FSC
      "Individual Contributor": "Contributeur individuel",
      "Manager / Sr. Manager": "Responsable principal et responsable",
      "Director / Sr. Director": "Directeur principal et directeur",
      "Vice President": "Vice-président",

      // Choose candidate - Field
      "Beauty Advisor": "Conseiller beauté",
      Lead: "Chef d’équipe",
      "Loss Prevention": "Prévention des pertes",
      Manager: "Responsable de magasin",
      "Store Director": "Directeur de magasin",
      "District Manager": "Responsable de district",
      "Regional Director": "Directeur régional",

      // Sub Core Comps
      "Directs Work": "Supervise le travail",
      "Drive Results": "Obtenir des résultats",
      "Ensures Accountability": "Prise de responsabilités",
      "Plans & Aligns": "Planification et harmonisation",
      "Develops Talent": "Perfectionnement des employés",
      "Interpersonal Savvy": "Compétences interpersonnelles",
      "Organizational Savvy": "Connaissances organisationnelles",
      "Client Focus": "Orientation vers la clientèle",
      "Manages Complexity": "Gestion des situations complexes",
      "Instills Trust": "Inspirer la confiance",
      "Manages Ambiguity": "Gestion des situations ambiguës ",
      "Self Development": "Perfectionnement personnel",
      "Decision Quality": "Capacités décisionnelles",
      "Situational Adaptability": "Souplesse situationnelle",
      "Optimizes Work Processes": "Optimisation des méthodes de travail",
      "Communicates Effectively": "Communication efficace",
      Collaborates: "Collaborates",
      Courage: "Courage",
      "Strategic Mindset": "Pensée stratégique",
      "Drives Engagement": "Mobilisation de l'engagement",
      Resourcefulness: "Esprit d’initiative ",
      "Drives Vision & Purpose": "Mobilisation autour d’une vision et d’un but",
      "Financial Acumen": "Sens des affaires",
      "Attracts Top Talent": "Attire les gens de talent les plus doués",
      "Business Insight": "Connaissance du marché",
      "Builds Effective Teams": "Création d’équipes efficaces",

      "Builds Networks": "Builds Networks fr",

      // Core Comps
      "Be your Best Self": "Donner le meilleur de soi-même",
      "Drive Results": "Obtenir des résultats ",
      "Focus on People": "Donner la priorité aux gens ",
      "Ignite Thought": "Susciter la réflexion ",
      "Celebrate Beauty": "Célébrer La Beauté",
      Leadership: "Leadership",

      // NEW CORE COMPS
      "Values Uniqueness & Differences":
        "Favorise l'unicité et les différences",
      "Agility & Manages Ambiguity": "Souplesse et gestion des ambiguïtés",
      "Client Focus": "Orientation vers la clientèle",
      Collaborates: "Collaboration",
      "Cultivates Creativity & Disruption": "Cultive la créativité",
      "Drives Results": "Obtention de résultats",
      Resilience: "Résilience",
      "Strategic Vision": "Vision stratégique",
      "Engages & Inspires": "S'engage et inspire",
      "Helps Others Grow": "Aide les autres à évoluer",

      // Values
      Balance: "Équilibre",
      Expertise: "Expertise",
      Initiative: "Initiative",
      Innovation: "Innovation",
      Passion: "Passion",
      Respect: "Respect",
      Teamwork: "Travail d'équipe",

      // Values Def
      "Demonstrating care for ourselves and each other":
        "Quand vient le temps de prendre soin de nous et des autres.",
      "With a growth mind-set, creating experiences that demonstrate our position as the beauty destination":
        "Avec une philosophie axée sur la progression, en créant des expériences qui démontrent notre position en tant que destination beauté.",
      "Taking ownership and holding ourselves accountable for driving business results":
        "En prenant ses responsabilités et en assumant le devoir de pousser les ventes.",
      "Taking risks to enhance the client experience":
        "En prenant des risques afin de rehausser l'expérience client.",
      "Putting our clients at the centre of everything we do":
        "En plaçant le client au cœur de toutes nos actions. ",
      "Fostering a sense of belonging for everyone.":
        "En inspirant un sentiment d'appartenance pour tous.",
      "Working together to accomplish any task at hand":
        "En travaillant ensemble pour accomplir n'importe quelle tâche.",

      // NEW CORE COMP DEF
      "Valuing personal differences & cultures and  the perspectives they bring, leveraging  diversity of angles.":
        "Favorise les différences personnelles et culturelles ainsi que la vision que ces différences apportent, exploitant ses différences de point de vue.",
      "Making sense of complex or incomplete  information to solve problems – Able to  decide & act quickly and effectively  without having the “full picture” in hand.":
        "Trouve un sens aux informations incomplètes ou complexes pour résoudre les problèmes — Capable de décider et d'agir rapidement et efficacement sans avoir une « vision globale » disponible.",
      "Building strong client relationships & delivering client-centric solutions.":
        "Bâtir de solides relations avec la clientèle et offrir des solutions axées sur les clients.",
      "Interacting trustfully & openly with diverse groups of people, building relationships within & beyond the organization. Working collectively, highlighting wins, celebrating & crediting the team & individuals for successes.":
        "Interagit ouvertement et de façon confiante avec divers groupes de personnes, tisse des liens à l'intérieur et à l'extérieur de l'entreprise. Travaille de façon collaborative, met en valeur les bons coups, félicite et donne le mérite à l'équipe et aux personnes pour leurs succès.",
      "Challenge-driven, rethinking traditions, practices & rules, pushing boundaries & status quo. Not afraid of failure, thinking  “out of the box” and acting accordingly.":
        "Motivé par les défis, repense les façons de faire et les règles, repousse les obstacles et le statu quo. N'a pas peur de l'échec, sort des sentiers battus et agit en conséquence.",

      "Making good & timely decisions to take  the organization forward with a consistent commitment to meeting stretching  objectives & results, operating with energy & passion for the collective good.":
        "Prend de bonnes décisions et moment opportun pour amener l'entreprise plus loin. Engagement constant à rencontrer et dépasser les objectifs et les résultats. Agit avec énergie et passion pour le bien collectif.",
      "Rebounding from setbacks & adversity when facing difficult situations.":
        "Rebondir après un échec et faire face à l’adversité lorsque la situation est difficile.",
      "Seeing ahead to future possibilities & translating them into a compelling vision, & breakthrough strategies to pursue that vision":
        "Prévoir les possibilités futures et les traduire en stratégies inédites.",
      "Creating a climate where people are empowered & motivated to do their best to help the organization achieve its objectives.":
        "Créer un climat dans lequel les personnes ont à cœur d’aider au maximum l’organisation à atteindre ses objectifs.",
      "Creating a climate where people are encouraged to speak up and grow.  Understanding people’s strengths, needs & aspirations, pushing them to challenge  their own comfort zone & providing support to help them reach their potential.":
        "Créer un climat où les gens sont encouragés à parler et à évoluer. Comprendre les forces, les besoins et les aspirations des personnes et les pousser à dépasser leur propre zone de confort. Offrir du soutien pour les aider à atteindre leur potentiel maximum.",

      // Core Comp Def
      "Operating effectively, even when things are not certain or the way forward is not clear.":
        "Agir efficacement, même en période d’incertitude ou de doute.",
      "Actively seeking new ways to grow and be challenged using both formal and informal development channels.":
        "Cherche activement de nouvelles façons de se perfectionner et d’être mis à l’épreuve en utilisant des méthodes formelles et informelles.",

      "Adapting approach and demeanor in real time to match the shifting demands of different situations.":
        "Adapting approach and demeanor in real time to match the shifting demands of different situations. fr",

      "Holding self and others accountable to meet commitments.":
        "Veiller à la responsabilisation de soi et d’autrui quant au respect des engagements.",
      "Knowing the most effective and efficient processes to get things done, with a focus on continuous improvement.":
        "Knowing the most effective and efficient processes to get things done, with a focus on continuous improvement. fr",
      "Planning and prioritizing work to meet commitments aligned with organizational goals.":
        "Planifier et prioriser le travail de manière à respecter les engagements correspondant aux objectifs de l’entreprise.",

      "Developing and delivering multi-mode communications that convey a clear understanding of the unique needs of different audiences.":
        "Élaborer et mettre en pratique divers modes de communication qui tiennent compte des besoins propres à chaque public.",
      "Developing people to meet both their career goals and the organization’s goals.":
        "Contribuer au perfectionnement des employés pour les aider à atteindre leurs objectifs de carrière de même que les objectifs de l’entreprise.",
      "Navigating comfortably through complex organizational dynamics":
        "Évoluer avec aisance malgré une dynamique organisationnelle complexe",

      "Building strong client relationships and delivering client-centric solutions":
        "Building strong client relationships and delivering client-centric solutions fr",
      "Making good and timely decisions that keep the organization moving forward.":
        "Prendre rapidement les bonnes décisions pour permettre à l’entreprise de progresser.",
      "Making sense of complex, high quantity, and sometimes contradictory information to effectively solve problems":
        "Analyser de nombreux renseignements complexes et parfois contradictoires pour résoudre les problèmes efficacement.",
      "Securing and deploying resources effectively and efficiently.":
        "Securing and deploying resources effectively and efficiently. fr",
      "Creating a climate where people are motivated to do their best to help the organization achieve its objectives.":
        "Creating a climate where people are motivated to do their best to help the organization achieve its objectives. fr",

      "Seeing ahead to future possibilities and translating them into breakthrough strategies.":
        "Seeing ahead to future possibilities and translating them into breakthrough strategies. fr",
      "Stepping up to address difficult issues, saying what needs to be said.":
        "Affronter les problèmes et les conversations difficiles.",
      "Providing direction, delegating, and removing obstacles to get work done.":
        "Faire preuve de leadership, déléguer et lever les obstacles pour atteindre les résultats.",

      "Building partnerships and working collaboratively with others to meet shared objectives.":
        "Building partnerships and working collaboratively with others to meet shared objectives. fr",
      "Building strong-identity teams that apply their diverse skills and perspectives to achieve common goals":
        "Bâtir des équipes solides et diversifiées qui utilisent leurs compétences et leurs perspectives individuelles pour atteindre des objectifs communs.",

      "Painting a compelling picture of the vision and strategy that motivates others to action.":
        "Painting a compelling picture of the vision and strategy that motivates others to action. fr",
      "Consists of our Get, Give, Teach, Sell model. This models supports our business through selling, and providing a personalized experience to all our clients.":
        "Consists of our Get, Give, Teach, Sell model. This models supports our business through selling, and providing a personalized experience to all our clients.",
      "Building strong-identity teams that apply their diverse skills and perspectives to achieve common goals.":
        "Bâtir des équipes solides et diversifiées qui utilisent leurs compétences et leurs perspectives individuelles pour atteindre des objectifs communs.",
      "Navigating comfortably through complex organizational dynamics.":
        "Évoluer avec aisance malgré une dynamique organisationnelle complexe",
      "Builds strong client relationships and delivering client-centric solutions.":
        "Bâtir de solides relations avec la clientèle et offrir des solutions axées sur les clients.",

      "Making sense of complex, high quantity, and sometimes contradictory information to effectively solve problems.":
        "Making sense of complex, high quantity, and sometimes contradictory information to effectively solve problems. fr",
      "Gaining the confidence and trust of others through honesty, integrity, and authenticity.":
        "Gagner la confiance des autres en faisant preuve d’intégrité et d’authenticité.",
      "Consistently achieving results, even under tough circumstances.":
        "Atteindre les résultats, même en situation difficile.",
      "Ability to understand the complexity of a retail business and leading a team successfully through a changing landscape.":
        "Ability to understand the complexity of a retail business and leading a team successfully through a changing landscape. fr",
      "Consistently achieving results, even under tough circumstances":
        "Atteindre les résultats, même en situation difficile.",
      "Attracting and selecting the best talent to meet current and future business needs.":
        "Attirer et choisir les meilleurs talents pour répondre aux besoins présents et futurs de l’entreprise.",
      "Interpreting and applying understanding of key financial indicators to make better business decisions.":
        "Interpréter les indicateurs financiers clés pour prendre de meilleures décisions d’affaires.",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    detection: {
      order: ["navigator"],
      // caches: ['cookie'],
      // caches: ['localStorage', 'cookie'],
    },

    // lng: "fr", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;

// import i18next from 'i18next';
// import HttpApi from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';

// i18next.use(HttpApi).use(LanguageDetector).init({
//     detection: {
//       order: ['navigator', ],
//       // caches: ['cookie'],
//       // caches: ['localStorage', 'cookie'],
//     },

//     // lng: "fr", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
//     // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
//     // if you're using a language detector, do not define the lng option
//     // backend: {
//     //   loadPath: '/locales/{{lng}}/translation.json',
//     // },
//     interpolation: {
//       escapeValue: false // react already safes from xss
//     },

//   });

// export default i18next;
