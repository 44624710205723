import React, { useEffect, useState, useMemo, useContext } from "react";
import { useForm } from 'react-hook-form';
import { useAuthState } from "react-firebase-hooks/auth";
import { isLogin, logout, auth, db } from '../../../Helper/firebase';
import { useTranslation, Trans } from 'react-i18next';
import {userInfoContext} from '../../Authentication/userInfoContext';
import { getDatabase, ref, onValue} from "firebase/database";
import { useList } from 'react-firebase-hooks/database';
import StepFooter from './StepFooter/StepFooter';
import i18next from "i18next";
import SendAlert from './../../General/SendAlert';
import NavBarAuth from './NavBarAuth';

const Values = ({prevStep, nextStep, handleChangeArray, formValues, valueContent, getValueQuestions, userInfoAdmin, resourceFileNamesList, resourceFileURLsList, allResourcesDictList}) => {
	const [boxState, setBoxState] = useState([])
	const [values, setValues] = useState({})
	const [alertInfo, setAlertInfo] = useState({open:false, alertMessage:'', variant:''});

	const { register, handleSubmit, errors, reset } = useForm()
	const { t, i18n } = useTranslation()
	// const lang = i18next.language.substr(0, 2)

	useEffect(() => {		
		setValues(Object.keys(valueContent))
		var obj = createFreshObjectForBoxState(valueContent)
		
		// if there is values in form values, update obj state of boxes to be rendered so that selection is shown
		if (formValues.selected_values!=''){
			obj = updateObjectWithPreviousSelections(obj)
		}

		setBoxState(obj)

	}, [valueContent]);


	useEffect(() => {
  		window.scrollTo(0, 0)
	}, [])
		
	// useEffect(() => {		

	// }, [boxState]);


	const Previous = e => {
		e.preventDefault();
		prevStep();

		// close the alert
		handleAlert([])
	}


	const Continue = e => {
		getValueQuestions()
		e.preventDefault();
		nextStep();
	}


	function getAllSelections(obj){
		var selections = []
		var val_keys = Object.keys(valueContent)

		Array.from(Object.keys(obj.selected), (e, i) => {

			var val = obj.selected[val_keys[i]]

			if(val == true){
				selections.push(val_keys[i])
			}			
		})

		return selections
	}


	function update(event){

		const selectedValue = event.target.value

		var obj = boxState

		if(event.target.checked && !boxState.selected[selectedValue]){
			obj.selected[selectedValue]=true			
		}
		else if(boxState.selected[selectedValue]){
			obj.selected[selectedValue]=false
		}
	
		setBoxState(obj)

		var allSelectedValues = getAllSelections(obj)
		handleChangeArray(event, allSelectedValues)
		handleAlert(allSelectedValues)
	}


	// function updateBoxState (selectedValue){
	// 	console.log(boxState)
	// 	var obj = boxState

 //  		Object.values(obj.selected)

	// 	obj.selected[selectedValue]=true

	// 	// console.log(boxState.selected[selectedValue])
	// 	// setBoxState(boxState.selected[selectedValue]=true)
	// 	setBoxState(obj)
	// }

	function handleAlert(selectionsArr) {

		var count = selectionsArr.length

		if (count>3){
		    setAlertInfo({open:true, alertMessage:t("Select a minimum of 1 value to a maximum of 3 values."), variant:'danger'})
			}		  
		else{
	    	setAlertInfo({open:false, alertMessage:'', variant:''})
		  	}
	}


	function createFreshObjectForBoxState (valueContent){
		
		var valueKey = Object.keys(valueContent)
		
		var obj = {selected:{}}

		valueKey.map((item, i)=>obj.selected[item]={})
		valueKey.map((item, i)=>obj.selected[item]=false)

		return obj
	}


	function updateObjectWithPreviousSelections(obj){
		const selectedValues = formValues.selected_values


		if(selectedValues!=''){
			{Array.from(selectedValues, (e, i) => {
				obj.selected[selectedValues[i]]=true
			})}

			var allSelectedValues = getAllSelections(obj)
			handleAlert(allSelectedValues)
		}

		return obj
	}


	return (
		<div>
			<NavBarAuth userInfoAdmin={userInfoAdmin} resourceFileNamesList={resourceFileNamesList} resourceFileURLsList={resourceFileURLsList} allResourcesDictList={allResourcesDictList}/>

	    	<div className="container">

				<h2 className="section-title">{t("OUR VALUES")}</h2>
				<br/>
				<p className="selection-limit">{t("Pick between 1 to 3 values that you want to assess in your interview:")}</p>


				<div className="row" id='generate_values'>
					{Array.from(values, (e, i) => {
						return (
							<div key = {i} className="col-md-4" >
								<label className={boxState.selected[values[i]]?"btn btn-outline-info " + values[i].toLowerCase() + " active": "btn btn-outline-info " + values[i].toLowerCase()}>								
									<input {...register("selected_values", {onChange: (e) => update(e)})} type="checkbox" name="selected_values" value={values[i]}/>
									<div class="option_heading">
										<p>                        					                        
											<Trans>{t(values[i])}</Trans>										
										</p>
									</div>
									<div class="description">
										<Trans>{valueContent[values[i]]}</Trans>               
									</div> 								
								</label>
			      			</div>
						)
					})}      	            
				</div>

				<br/>

				{alertInfo.open && <SendAlert alertMessage={alertInfo.alertMessage} variant={alertInfo.variant}/>}
				<div id="page_buttons">
					<button type="button" className="btn btn-outline-secondary btn-sm btn-space" onClick={ Previous }>{t("Back")}</button>
					<button type="button" className="btn btn-outline-secondary btn-sm btn-space" onClick={ Continue } disabled={formValues.selected_values.length>3 || formValues.selected_values.length==0}>{t("Next")}</button>
				</div>

				<StepFooter formValues={formValues} currentSelectionPage='selected_values'/>	
			</div>	
		</div>	
	)
}

export default Values