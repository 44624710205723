import React, {useState, useEffect} from 'react'
import SendAlert from '../../../General/SendAlert';
import Spinner from 'react-bootstrap/Spinner';
import { useForm } from 'react-hook-form';
import { downloadQuestions, downloadUserTemplate, downloadUsers } from "../../../../Helper/APIService";
import XLSX from 'xlsx';

const GeneralAdminGet = () => {
	const [selectedFile, setSelectedFile] = useState();
	const [selectedFeature, setSelectedFeature] = useState();
	const [selectedWorkLocation, setSelectedWorkLocation] = useState();
	const [loading, setLoading] = useState(false);
	const { register, handleSubmit, errors, reset } = useForm()


	function handleSubmitGetRequest(data) {
		setLoading(true)
		
		const dataArray = new FormData();
		var work_location = data.work_location
		var feature = data.feature

		var apiCall

		dataArray.append('work_location', work_location)
		dataArray.append('feature', feature)

		if (feature == "get_profiles"){
			apiCall = downloadUsers(dataArray)
		} 
		else if (feature == "get_question_file"){
			apiCall = downloadQuestions(dataArray)
		}
		else if (feature == "get_user_template"){
			apiCall = downloadUserTemplate(dataArray)
		}


		apiCall.then((resp) => {
			setLoading(false)
			console.log(resp)
			// reset()
    	})
		.catch((error) => {
			console.log(error)
			setLoading(false)
			// reset()
		})

	}

	return (
		<div>
			<h2>
				Download File: &nbsp; &nbsp;

	        	{loading &&
			        <Spinner animation="border" role="status" variant="danger">
	  					<span className="visually-hidden"></span>
					</Spinner>
				}
			</h2>

	        <form method="POST" action="" enctype="multipart/form-data" content-type="multipart/form-data" onSubmit={handleSubmit(handleSubmitGetRequest)}>
	            <div className="form-group">
	                <select  {...register("work_location", {onChange:(e) => setSelectedWorkLocation(e.target.value)})} className="custom-select" required>
	                    <option value="" selected="true" disabled="disabled">Select work location</option>
	                    <option value="Field">Field</option>
	                    <option value="FSC">FSC</option>
	                </select>
	            </div>

	            <div className="form-group">
	                <select {...register("feature", {onChange:(e) => setSelectedFeature(e.target.value)})} className="custom-select" required>
	                    <option value="" selected="true" disabled="disabled">File Name</option>
	                    <option value="get_profiles">Users File</option>
	                    <option value="get_question_file">Questions File</option>
	                    <option value="get_user_template">Users Template File</option>
	                </select>
	                <div className="invalid-feedback">Example invalid custom select feedback</div>
	            </div>			

	            <button className="btn btn-outline-secondary btn-sm btn-space" style={{minWidth: '5rem', padding: '0', fontSize: '1rem'}} type="submit">Submit</button>

	        </form>

	        <br/>
	        <br/>
		</div>
	)
}

export default GeneralAdminGet