import React, { useEffect, useState }from 'react'
import Alert from 'react-bootstrap/Alert';

const SendAlert = ({alertMessage, variant}) => {
	
	// const [variant, setVariant] = useState("danger")
	const [show, setShow] = useState(true);
  

    // if (show) {
      return (
        <Alert variant={variant} show={show} onClose={() => setShow(false)}>
          <button type="button" className="close" aria-label="Close"><span aria-hidden="true" onClick={() => setShow(false)}>×</span></button>
          <p>
            {alertMessage}
          </p>
        </Alert>
      );
    // }

  // return <div></div>;
}

export default SendAlert