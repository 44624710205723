import React from 'react'

// https://blog.devgenius.io/create-a-multi-step-form-with-reactjs-322aa97a2968
import Locations from './SelectionSteps/Locations';
import Roles from './SelectionSteps/Roles';
import Values from './SelectionSteps/Values';
import CoreCompetencies from './SelectionSteps/CoreCompetencies';
import Questions from './SelectionSteps/Questions';
import { isLogin, logout, auth, db, storage, storage_ref } from '../../Helper/firebase';
import { getDatabase, ref, onValue, child, get } from "firebase/database";
import i18next from "i18next";
import { listAll, getMetadata, deleteObject, getDownloadURL } from "firebase/storage";

export default class QuestionForm extends React.Component {
	// const { t, i18n } = useTranslation()
	

	constructor(props) {
    super(props);

    this._isMounted = false;
}

	componentDidMount() {
		this._isMounted = true;
		this.getUserInfo()
		this.getAllContent()
		this.getRoleType()		
		this.getResourceInfoRender("Field")	
		// this.getResourceFiles("FSC")	
	} 


   	componentWillUnmount() {
    	this._isMounted = false;
    }

	state = {
		// form values 
		step:1,
		selected_location:'',
		selected_role:'',
		selected_values:[],
		selected_core_competencies:'',
		selected_questions:'',

		// user info
		userInfo:'',
		content:{},
		roleAccess:{},
		locationAccess:[],
		roleType:{},
		questions:{},
		valueQuestions:{},
		valueQuestionsEN:{},
		coreCompQuestions:{},
		coreCompQuestionsEN:{},
		resourceFileNamesList:[],
		resourceFileURLsList:[],
		allResourcesDictList:[],
	}
	

	getRoleAccess = (userLevel) => {

		// position/level of the user logging in
		// var userLevel = this.state.userInfo.Level
		
		const roleAccessRef = ref(db, 'access/role access/' + userLevel);

		// need to make sure that the user is signed in
		auth.onAuthStateChanged(user => {
			onValue(roleAccessRef, (snapshot) => {
				const data = snapshot.val();
		  		this.setState({ roleAccess: data });	
			});
		})		
	}

	getResourceInfoRender = (work_location) => {
		var fileNamesList = []
		var fileURLsList = []
		
		var docDict = {};

		const resourceRef = storage_ref(storage, work_location+'/Resources');
		
		// Loop over the documents in the storage bucket
		listAll(resourceRef).then(function(result) {
		result.items.forEach(function(itemRef) {
			// Get the document name
			var docName = itemRef.name;
			// Get the download URL for the document
			getDownloadURL(itemRef).then(function(url) {
				// Add the document name and URL to the dictionary
				docDict[docName] = url;
				// console.log(docDict)
			});
		});
		},
		this.setState({allResourcesDictList: docDict})
		).catch(function(error) {
		console.log(error);
		});

		this.pull_files_names_from_storage(work_location, fileNamesList)
		this.pull_files_urls_from_storage(work_location, fileURLsList)	

	}


	getResourceFiles = (work_location) => {
		// const resourceRef = storage_ref(storage, work_location + '/Resources');
		const allLocation = ['FSC', 'Field']

		var fileNamesList = this.state.resourceFileNamesList
		var fileURLsList = this.state.resourceFileURLsList

		for (var i = 0; i <= allLocation.length-1; i++) {	
			this.pull_files_names_from_storage(allLocation[i], fileNamesList)
			this.pull_files_urls_from_storage(allLocation[i], fileURLsList)
		}		
	}

	pull_files_names_from_storage = async (work_location, fileNamesList) => {
    	
    	const resourceRef = storage_ref(storage, work_location+'/Resources');
    	

		var itemsProcessed = 0;

		listAll(resourceRef)
		.then((res) => {				
			for (var i = 0; i <= res.items.length-1; i++) {	
				var itemRef = res.items[i]
				getMetadata(itemRef).then((metaData)=> {
					itemsProcessed++;
					fileNamesList.push(metaData.name)

					if(itemsProcessed === res.items.length) {
						// console.log(fileNamesList)
				      this.setState({resourceFileNamesList: fileNamesList})
				    }
				})
			}
		})			
	}


	pull_files_urls_from_storage = async (work_location, fileURLsList) => {
    	
    	const resourceRef = storage_ref(storage, work_location+'/Resources');
    	

		var itemsProcessed = 0;

		listAll(resourceRef)
		.then((res) => {				
			for (var i = 0; i <= res.items.length-1; i++) {	
				var itemRef = res.items[i]
				getDownloadURL(itemRef).then((downloadURL)=> {
					itemsProcessed++;
					fileURLsList.push(downloadURL)

					if(itemsProcessed === res.items.length) {
						// console.log(fileURLsList)
				      this.setState({resourceFileURLsList: fileURLsList})
				    }
				})
			}
		})			
	}



	getLocationAccess = (userLevel) => {

		// position/level of the user logging in
		// var userLevel = this.state.userInfo.Level
		
		const locationAccessRef = ref(db, 'access/location access/' + userLevel);

		
		// need to make sure that the user is signed in
		auth.onAuthStateChanged(user => {
			onValue(locationAccessRef, (snapshot) => {
				const data = snapshot.val();
		  		this.setState({ locationAccess: data });		  		
			});
		})		
	}


	getRoleType = () => {

		const roleTypeRef = ref(db, 'access/role type/');

		
		// need to make sure that the user is signed in
		auth.onAuthStateChanged(user => {
			onValue(roleTypeRef, (snapshot) => {
				const data = snapshot.val();
		  		this.setState({ roleType: data });	
			});
		})		
	}


	getAllContent = () => {
		const lang = i18next.language.substr(0, 2)
		const allContentRef = ref(db, 'content/'+ lang);

		
		// need to make sure that the user is signed in
		auth.onAuthStateChanged(user => {

			onValue(allContentRef, (snapshot) => {
				const data = snapshot.val();
		  		this.setState({ content: data });	
			});
		})

	}

	
	getValueQuestions = () => {
		var location = this.state.selected_location
		var values = this.state.selected_values

		var questionObj = {}

		const lang = i18next.language.substr(0, 2)

		var valuesQuestionRef = []
		var promises = []
		const dbRef = ref(db)

		Array.from(values, (e, i) => {
			promises.push(get(child(dbRef, 'questions/' + location + '/Values' + '/' + lang + '/' + values[i])))
		})


		Promise.all(promises).then((snapshots) => {
			Array.from(snapshots, (snapshot, i) => {
				var data = snapshot.val()
				var value = snapshot.key
				questionObj[value]=data
			})
			this.setState({ valueQuestions: questionObj })
		});	


		var promisesEN = []
		var questionObjEN = {}

		if (lang=='fr'){
			Array.from(values, (e, i) => {
				promisesEN.push(get(child(dbRef, 'questions/' + location + '/Values' + '/' + 'en' + '/' + values[i])))
			})

			Promise.all(promisesEN).then((snapshots) => {
				Array.from(snapshots, (snapshot, i) => {
					var data = snapshot.val()
					var value = snapshot.key
					questionObjEN[value]=data
				})
				this.setState({ valueQuestionsEN: questionObjEN })
			});

		} else {
			this.setState({ valueQuestionsEN: questionObj })
		}				

	}


	getCoreCompQuestions = () => {
		var location = this.state.selected_location
		var role = this.state.selected_role
		var coreComps = this.state.selected_core_competencies

		var questionObj = {}

		const lang = i18next.language.substr(0, 2)

		var promises = []
		const dbRef = ref(db)


		Array.from(coreComps, (e, i) => {
			promises.push(get(child(dbRef, 'questions/' + location + '/' + role + '/' + lang + '/' + coreComps[i])))
		})
		
		
		
		Promise.all(promises).then((snapshots) => {
			Array.from(snapshots, (snapshot, i) => {
				var data = snapshot.val()
				var coreComp = snapshot.key
				questionObj[coreComp]=data
			})
			this.setState({ coreCompQuestions: questionObj })
		});	



		var promisesEN = []
		var questionObjEN = {}

		if (lang=='fr'){
			Array.from(coreComps, (e, i) => {
				promisesEN.push(get(child(dbRef, 'questions/' + location + '/' + role + '/' + 'en' + '/' + coreComps[i])))
			})

			Promise.all(promisesEN).then((snapshots) => {
				Array.from(snapshots, (snapshot, i) => {
					var data = snapshot.val()
					var coreComp = snapshot.key
					questionObjEN[coreComp]=data
					// console.log(data)
				})
				this.setState({ coreCompQuestionsEN: questionObjEN })
				// console.log(questionObjEN)
			});

		} else {			
			this.setState({ coreCompQuestionsEN: questionObj })
		}	

	}



	getUserInfo = () => {
		// ADDING DUMMY VARIABLE DATA
		const allUsersRef = ref(db, 'users/');
		// var dummy_data = {Admin: true, District: 'bc metro', First_Name: 'a', Last_Name: 'sasa', Level: 'Vice President'}

		auth.onAuthStateChanged(user => {
			const email = user.email
			const userKey = email.split('@')[0].replace(/[^a-z0-9+]+/gi, '_');     
			
			onValue(allUsersRef, (snapshot) => {
				const data = snapshot.val();
		  // 		this.setState({ userInfo: dummy_data });	
  		// 		this.getRoleAccess(dummy_data.Level)
				// this.getLocationAccess(dummy_data.Level)	

		  		this.setState({ userInfo: data[userKey] });
  				this.getRoleAccess(data[userKey].Level)
				this.getLocationAccess(data[userKey].Level)	

			});
		})		
	}

	// go back to previous step
	prevStep = () => {
		const { step } = this.state;
		this.setState({ step: step - 1 });
	}

	// proceed to the next step
	nextStep = () => {
	  const { step } = this.state;
	  this.setState({ step: step + 1 });
	}

	// handle field change
	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	}

	handleChangeArray = (event, arr) => {
		this.setState({ [event.target.name]: arr });
	}


	render() {
		const { step } = this.state;
		const { selected_location, selected_role, selected_values, selected_core_competencies, selected_questions, userInfo, content, roleAccess, locationAccess, roleType, questions } = this.state;
		const formValues = { selected_location, selected_role, selected_values, selected_core_competencies, selected_questions }
		
		switch (step) {
			case 1: 
				return (
		  			<Locations 
		  				nextStep = { this.nextStep }
		  				handleChange = { this.handleChange }
		  				formValues = { formValues }
		  				locationAccess = {this.state.locationAccess}
		  				userInfoAdmin = {this.state.userInfo.Admin}
		  				resourceFileNamesList = {this.state.resourceFileNamesList}
		  				getResourceInfoRender = {this.getResourceInfoRender}
		  				resourceFileURLsList = {this.state.resourceFileURLsList}
						allResourcesDictList = {this.state.allResourcesDictList}
	  				/>
				)
			case 2: 
				return (
		  			<Roles 
		  				prevStep = { this.prevStep }
		  				nextStep = { this.nextStep }
		  				handleChange = { this.handleChange }
		  				formValues = { formValues }
		  				roleAccess = { this.state.roleAccess }
		  				userInfoAdmin = {this.state.userInfo.Admin}
		  				resourceFileNamesList = {this.state.resourceFileNamesList}
		  				resourceFileURLsList = {this.state.resourceFileURLsList}
						allResourcesDictList = {this.state.allResourcesDictList}
		  			/>
		)
			case 3: 
				return (
		  			<Values 
		  				prevStep = { this.prevStep }
		  				nextStep = { this.nextStep }
		  				handleChangeArray = { this.handleChangeArray }
		  				formValues = { formValues }
		  				valueContent = {this.state.content['Values']}
		  				getValueQuestions = {this.getValueQuestions}
		  				userInfoAdmin = {this.state.userInfo.Admin}
		  				resourceFileNamesList = {this.state.resourceFileNamesList}
		  				resourceFileURLsList = {this.state.resourceFileURLsList}
						allResourcesDictList = {this.state.allResourcesDictList}
		  			/>
		)
			case 4:
				return (
		  			<CoreCompetencies 
		  				prevStep = { this.prevStep }
		  				nextStep = { this.nextStep }
		  				handleChangeArray = { this.handleChangeArray }
		  				formValues = { formValues }
		  				coreCompetenciesContentForSelectedRole = {this.state.content['Core Competencies'][roleType[formValues.selected_role]]}
		  				roleType = {this.state.roleType}
		  				getCoreCompQuestions = {this.getCoreCompQuestions}
		  				valueQuestions = {this.state.valueQuestions}
		  				userInfoAdmin = {this.state.userInfo.Admin}
		  				resourceFileNamesList = {this.state.resourceFileNamesList}
		  				resourceFileURLsList = {this.state.resourceFileURLsList}
						allResourcesDictList = {this.state.allResourcesDictList}
		  			/>
		)
			case 5:
				return (
		  			<Questions 
		  				prevStep = { this.prevStep }
		  				nextStep = { this.nextStep }
		  				handleChangeArray = { this.handleChangeArray }
		  				formValues = { formValues }		  				
		  				questions = {this.state.questions}
		  				coreCompQuestions = {this.state.coreCompQuestions}
		  				coreCompQuestionsEN = {this.state.coreCompQuestionsEN}
		  				valueQuestions = {this.state.valueQuestions}
		  				valueQuestionsEN = {this.state.valueQuestionsEN}
		  				valueContent = {this.state.content['Values']}
		  				coreCompetenciesContentForSelectedRole = {this.state.content['Core Competencies'][roleType[formValues.selected_role]]}
		  				userInfoAdmin = {this.state.userInfo.Admin}
		  				resourceFileNamesList = {this.state.resourceFileNamesList}
		  				resourceFileURLsList = {this.state.resourceFileURLsList}
		  				userInfo = {this.state.userInfo}
						allResourcesDictList = {this.state.allResourcesDictList}
		  			/>
		)

		// never forget the default case, otherwise VS code would be mad!
		default: 
		 // do nothing
		}

	}
}