import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, db } from "../../Helper/firebase";
import { sendVerificationEmail, sendIntroductionEmail, registerUser } from "../../Helper/APIService";
// import { useCreateUserWithEmailAndPassword } from "react-firebase-hooks/auth";
import SendAlert from '../General/SendAlert';
import { logoutPreviousUser, firebase, logout } from '../../Helper/firebase';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { getDatabase, ref as database_ref, onValue} from "firebase/database";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

function Register() {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [alertInfo, setAlertInfo] = useState({open:false, alertMessage:'', variant:''});
  const [passwordMatchingInfo, setPasswordMatchingInfo] = useState({message:'not matching', textColor:'red', valid:false});
  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState("")
  const { register, handleSubmit, errors, reset } = useForm()
  const { t, i18n } = useTranslation()

  let navigate = useNavigate();


  useEffect(() => {
    validatePassword()
  }, [password, confirmPassword]);

    useEffect(() => {
    setPasswordMatchingInfo({message:t("not matching"), textColor:'red', valid:false})
  }, []);

  useEffect(() => {
    if (email.endsWith("@sephora.com")){
      setAlertInfo({open:false, alertMessage:'', variant:''})
    }    
  }, [email]);

  function validateForm() {    
    return email.length > 0 && password.length > 0 && confirmPassword.length > 0 && passwordMatchingInfo.valid && email.endsWith("@sephora.com")    
  }


  function validatePassword() {

    if(password.length > 0 && confirmPassword.length > 0){
        if (password==confirmPassword){          
          setPasswordMatchingInfo({message:t("matching"), textColor:'green', valid:true})
        } else {
          setPasswordMatchingInfo({message:t("not matching"), textColor:'red', valid:false})
        }
      }
    }


  const is_user_uploaded_by_admin = async (email) => {
 // var email = 'dew@sephora.com'
    var user_key = email.split('@')[0].replace(/[^a-z0-9+]+/gi, '_');
    const userRef = database_ref(db, 'users/' + user_key);
    onValue(userRef, (snapshot) => {
      const data = snapshot.val();
      console.log(data)
      setUserInfo(data)
      
    });

  }


  const registerWithEmailAndPassword = async () => {
    
    // condition for only registered user to go ahead
    registerUser({email, password})
    .then((resp) => {
      handleResponse(resp.message)
      reset()
    })
    .catch((error) => {
      handleResponse(error)
      console.log(error)
    });
  }
  

  function handleRegister(event) {

    // close alert if open        
    registerWithEmailAndPassword() 
    reset({'password':'', 'confirmPassword':''}) 
  }


  function handleResponse(response) {
    // add more error code conditions

    if (response=="ALREADY_EXISTS"){
        setAlertInfo({open:true, alertMessage:t("User already registered. Please reach out to admin if you have problems logging in"), variant:'danger'})
      }
    else if (response=="email not sent"){
        setAlertInfo({open:true, alertMessage:t("There was a problem. Please reach out to admin."), variant:'danger'})
      }
    else if (response=="email sent successfully"){
        setAlertInfo({open:true, alertMessage:t("Please verify your email to access the tool."), variant:'success'})
      }
    else if (response=="profile not uploaded by admin"){      
      setAlertInfo({open:true, alertMessage:t("Your profile could not be found. Please reach out to admin."), variant:'danger'})
    }
    else {
        setAlertInfo({open:true, alertMessage:t("There was a problem. Please reach out to admin."), variant:'danger'})
      }
  }
  

  return (
    <div>
      <div class="top-container">
        <Link to={'/'}>
          <img src="img/sephora_github_proj/logo.png" alt="Sephora"></img>
        </Link>
      </div>
      <div className="public_form">
        <div className="container-md">          
          <p>{t("Please complete the following information to register for an account")}</p>
        </div>
        <div className="container-sm">
          {alertInfo.open && <SendAlert alertMessage={alertInfo.alertMessage} variant={alertInfo.variant}/>}
          <form className="form-signin" onSubmit={handleSubmit(handleRegister)}>
              <input
                name='email'
                className="form-control"
                type="text"
                value={email}
                {...register("email", {onChange:(e) => setEmail(e.target.value)})}
                // onChange={(e) => setEmail(e.target.value)}
                placeholder={t("Work Email Address")}
                required="required" 
                pattern=".+@sephora.com" 
                onInvalid={e => e.target.setCustomValidity(t("Enter your @sephora.com email address"))}
                onInput={e => e.target.setCustomValidity('')}
              />
              <br />
              <input
                className="form-control"
                type="password"
                value={password}
                // onChange={(e) => setPassword(e.target.value)}
                {...register("password", {onChange:(e) => setPassword(e.target.value)})}
                placeholder={t("Password")}
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" 
                onInvalid={e => e.target.setCustomValidity(t("Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"))}
                onInput={e => e.target.setCustomValidity('')}
              />
              <br />
              <input
                className="form-control"
                type="password"
                value={confirmPassword}
                // onChange={(e) => setConfirmPassword(e.target.value)}
                {...register("confirmPassword", {onChange:(e) => setConfirmPassword(e.target.value)})}
                placeholder={t("Confirm Password")}
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" 
                onInvalid={e => e.target.setCustomValidity(t("Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"))}
                onInput={e => e.target.setCustomValidity('')}
              />
              <br />

              <span style={{color:passwordMatchingInfo.textColor}}>{passwordMatchingInfo.message}</span>
              <br />

              <button 
                className="btn btn-outline-secondary"
                // disabled={!validateForm()}
                disabled={!email}
              >
                {t("Register")}
              </button>        
          </form>
        </div>
      </div>
    </div>
  );
}
export default Register;


