import React, {Suspense, useEffect, useMemo, useState, useContext } from 'react';
import { Routes, Route, BrowserRouter, Navigate, Outlet, useLocation } from 'react-router-dom';

import LandingPage from '../../Component/LandingPage/LandingPage';
import QuestionForm from '../../Component/QuestionForm/QuestionForm';
import Login from '../../Component/Authentication/Login';
import Register from '../../Component/Authentication/Register';
import ResetPasswordRequest from '../../Component/Authentication/ResetPasswordRequest';
import UpdatePassword from '../../Component/Authentication/UpdatePassword';
import ResendVerificationEmail from '../../Component/Authentication/ResendVerificationEmail';
import AdminDashboard from '../../Component/AdminDashboard/AdminDashboard';
import Temp from '../../Component/General/Temp';
import { isLogin, logout, auth, db } from '../../Helper/firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import { getDatabase, ref, onValue, get, child} from "firebase/database";
import { useList } from 'react-firebase-hooks/database';
import {userInfoContext} from '../../Component/Authentication/userInfoContext';


const Main = () => {
  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState()
  // const {userAdmin, setUserAdmin} = useState(false)

  const [snapshots, db_loading, db_error] = useList(ref(db, 'users/'));

  useEffect(() => {
      getUserInfo()
  }, [])

  function getUserInfo(){
    // ADDING DUMMY VARIABLE DATA
    const allUsersRef = ref(db, 'users/');
    var dummy_data = {Admin: true, District: 'bc metro', First_Name: 'a', Last_Name: 'sasa', Level: 'Vice President'}

    auth.onAuthStateChanged(user => {
      if(user!=null){
        const email = user.email
      const userKey = email.split('@')[0].replace(/[^a-z0-9+]+/gi, '_');     
      
      get(child(ref(db), 'users/')).then((snapshot) => {
        const data = snapshot.val();
        setUserInfo(data[userKey]);
      })

      // onValue(allUsersRef, (snapshot) => {
      //     const data = snapshot.val();
      //     console.log(data)
      // // this.setState({ userInfo: data[userKey] });  
      //     setUserInfo({ dummy_data });

      // });
    }    
      })
      
  }


  // function get_user_info_from_database(email){
  //   if(!db_loading){
  //     var user_key = email.split('@')[0].replace(/[^a-z0-9+]+/gi, '_');      
  //     var user_info_snapshot = snapshots.filter(function(obj) { 
  //       return obj.key==user_key;
  //     });
  //     setUserInfo(user_info_snapshot.map((v) => (v.val())))
  //   }
  // }


  function RequireAuth({ children, redirectTo }) {
    return user ? children : <Navigate to={redirectTo} />
  }

  // allow to go if user is admin, else redirect to multipage form and it would be redirected to login if the user is not logged in  
  function AdminAuth({ children, redirectTo }) {
    // console.log(user)

    return userInfo.Admin ? children : <Navigate to={redirectTo} />
  }

            

  return (
    
      <Routes>
        {/* General */}
        <Route path="/" element={<LandingPage />} />

        {/* Authentication Flow*/}
        <Route exact path='/login' element={<Login />}/>
        <Route exact path='/register' element={<Register />}/>
        <Route exact path='/forgot-password' element={<ResetPasswordRequest />}/>
        <Route exact path='/update-password' element={<UpdatePassword />}/>
        <Route exact path='/verify' element={<ResendVerificationEmail />}/>
        <Route
          path="/logout"
          element={<Temp action='logout'/>} 
        />

        

        

        {/* Admin Dashboard */}
{/*        <Route
          path="/admin"
          exact
          element=<AdminDashboard />
        />*/}
        <Route
          path="/admin"
          exact
          element={
            <Suspense fallback={'Loading...'}>
              {loading 
                ?<div></div>
                :<RequireAuth redirectTo="/login">
                  <Suspense fallback={'Loading...'}>
                    {!userInfo
                      ?<div></div>
                      :<AdminAuth redirectTo="/login">
                          <AdminDashboard />
                        </AdminAuth>
                    }
                  </Suspense>
                </RequireAuth>
              }
            </Suspense>
          }
        />

        {/* Question Form */}

        <Route
          path="/generate-interview-guide"
          exact
          element={
            <Suspense fallback={'Loading...'}>
              {loading 
                ?<div></div>
                :<RequireAuth redirectTo="/login">
                      <QuestionForm/>
                  </RequireAuth>
              }
            </Suspense>
          }
        />      

        {/* adjusting from previous app */}
        <Route path='/multi_page_form' element={<Navigate replace to="/generate-interview-guide"/>}/>
        <Route path='/send_reset_password_request' element={<Navigate replace to="/forgot-password"/>}/>
        <Route path='/general_admin' element={<Navigate replace to="/admin"/>}/>
        <Route path='/fsc_resources' element={<Navigate replace to="/admin"/>}/>
        <Route path='/field_resources' element={<Navigate replace to="/admin"/>}/>

        
        <Route path='*' exact={true} element={<Navigate replace to="/"/>} />
      </Routes>    
  );



}

export default Main;