import React, { useEffect, useState, useMemo, useContext } from "react";
import { useForm } from 'react-hook-form';
import { useAuthState } from "react-firebase-hooks/auth";
import { isLogin, logout, auth as fb_auth, db } from '../../../Helper/firebase';
import { useTranslation, Trans } from 'react-i18next';
import {userInfoContext} from '../../Authentication/userInfoContext';
import { getDatabase, ref, onValue, set} from "firebase/database";
import { useList } from 'react-firebase-hooks/database';
import StepFooter from './StepFooter/StepFooter';
import NavBarAuth from './NavBarAuth';

const Roles = ({prevStep, nextStep, handleChange, formValues, roleAccess, userInfoAdmin, resourceFileNamesList, resourceFileURLsList, allResourcesDictList }) => {

	const [role, setRole] = useState([])
	const [boxState, setBoxState] = useState([])
	
	const { register, handleSubmit, errors, reset } = useForm()
	const { t, i18n } = useTranslation()

	var cleanRoleObj = {
		'Beauty Advisor':'Beauty Advisor', //stays same
		'Lead':'Lead', //stays same
		'Loss Prevention':'Loss Prevention',//stays same
		"Manager":"Manager", //stays same
		"Store Director": "Store Director", //stays same
		"District Manager": "District Manager", //stays same
		"Regional Director": "Regional Director", //stays same
		"Individual Contributor": "Individual Contributor", //stays same
		"Sr Manager or Manager": "Manager/Sr. Manager", // updated
		"Sr Director or Director": "Director/Sr. Director", // updated
		"Vice President":"Vice President", //stays same
	}

	useEffect(() => {
  		window.scrollTo(0, 0)
	}, [])
		
	useEffect(() => {	
		// writeData()
		setRole(roleAccess[formValues.selected_location])
		// console.log(roleAccess)
		// const roleAccessArr = getRolesForUser()
		var obj = createFreshObjectForBoxState(roleAccess[formValues.selected_location])
		

		// if there is values in form values, update obj state of boxes to be rendered so that selection is shown
		if (formValues.selected_role!=''){
			obj = updateObjectWithPreviousSelections(obj)
		}

		setBoxState(obj)

  }, [roleAccess]);


// 	function writeData() {
	  
// 	  set(ref(db, 'access/'), {'role access': roleAccessObj, 'location access': locationAccessObj, 'role type': positionObj}).then(() => {
//   console.log('completed')
// })
// .catch((error) => {
// 	console.log(error)
//   // The write failed...
// });
// 	  console.log(roleAccessObj)
// 	}

	const Previous = e => {
		e.preventDefault();
		prevStep();
	}


	const Continue = e => {
		e.preventDefault();
		nextStep();
	}


	function cleanStringForUser(r){
		return cleanRoleObj[r]

	}


	function update(event){
		const selectedVal = event.target.value
		const selectedRole = event.target.value

		if(event.target.checked){
			updateBoxState(selectedRole)
			handleChange(event)
		}	
	}


	function updateBoxState (selectedRole){
		var obj = createFreshObjectForBoxState(role)
		obj.selected[selectedRole]=true
		setBoxState(obj)
	}


	function createFreshObjectForBoxState (roleAccessArr){
		var obj = {selected:{}}
		// console.log(roleAccessArr)
		// console.log(formValues)
		var roleAccessForLocation = roleAccessArr
		roleAccessForLocation.map((item, i)=>obj.selected[item]={})
		roleAccessForLocation.map((item, i)=>obj.selected[item]=false)

		// console.log(obj)
		return obj
	}


	function updateObjectWithPreviousSelections(obj){
		const selectedRole = formValues.selected_role

		if(selectedRole!=''){
			obj.selected[selectedRole]=true
		}

		return obj
	}


	return (
		<div>
			<NavBarAuth userInfoAdmin={userInfoAdmin} resourceFileNamesList={resourceFileNamesList} resourceFileURLsList={resourceFileURLsList} allResourcesDictList={allResourcesDictList}/>

	    	<div className="container">
				<h2 className="section-title">{t("I'M HIRING FOR:")}</h2>    
			      <div className="row" id='generate_roles'>
			      	{Array.from(role, (e, i) => {
			      		return (
			      			<div key = {i} className="col-md-3" >
										<label className={boxState.selected[role[i]]?"btn btn-outline-dark active": "btn btn-outline-dark"}>
											<div className="centerd">
												<input {...register("selected_role", {onChange: (e) => update(e)})} type="radio" name="selected_role" value={role[i]}/> <Trans>{cleanStringForUser(role[i])}</Trans> 
											</div>
										</label>
					      	</div>
				      	)
			      	})}      	            
			    	</div>

						<div id="page_buttons">
							<button type="button" className="btn btn-outline-secondary btn-sm btn-space" onClick={ Previous }>{t("Back")}</button>
							<button type="button" className="btn btn-outline-secondary btn-sm btn-space" onClick={ Continue } disabled={!formValues.selected_role!=''}>{t("Next")}</button>
						</div>
						
						<StepFooter formValues={formValues} currentSelectionPage='selected_role'/>	

					</div>	
			</div>	
	)
}

export default Roles