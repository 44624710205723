// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';


import { getAuth, setPersistence, browserLocalPersistence, browserSessionPersistence, inMemoryPersistence } from "firebase/auth";

import { initializeApp } from "firebase/app"
import { getDatabase, ref as database_ref, onValue} from "firebase/database";


// import 'firebase/compat/firestore';
// import 'firebase/compat/database';
import { useAuthState } from "react-firebase-hooks/auth";
import { getStorage, ref as storage_ref, uploadBytes, getDownloadURL, listAll, getMetadata } from "firebase/storage";

  // const firebaseConfig = {
  //   apiKey: "AIzaSyBm0-xUqAY_VPcMdgY4bNr1J0hE9o1hNU0",
  //   authDomain: "sephoratoptalent.firebaseapp.com",
  //   databaseURL: "https://sephoratoptalent.firebaseio.com",
  //   projectId: "sephoratoptalent",
  //   storageBucket: "sephoratoptalent.appspot.com",
  //   messagingSenderId: "1076863988531",
  //   appId: "1:1076863988531:web:590b895c616edb0eb1c446",
  //   measurementId: "G-H557RRP0L7"
  // };

  const firebaseConfig = {
    apiKey: "AIzaSyBm0-xUqAY_VPcMdgY4bNr1J0hE9o1hNU0",
    authDomain: "sephoratoptalent.firebaseapp.com",
    databaseURL: "https://sephoratoptalent-refactored.firebaseio.com/",
    projectId: "sephoratoptalent",
    storageBucket: "sephoratoptalent-refactored",
    messagingSenderId: "1076863988531",
    appId: "1:1076863988531:web:590b895c616edb0eb1c446",
    measurementId: "G-H557RRP0L7"
  };
  
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const db = getDatabase(app)  
  const analytics_app = initializeApp({
                            databaseURL: "https://sephoratoptalent-analytics.firebaseio.com/"
                        }, 'analytics_app');
  const analytics_db = getDatabase(analytics_app);
  const storage = getStorage(app);
  const storageRef = storage_ref(storage);
  const browser_local_persistence = setPersistence(auth, browserLocalPersistence);

  // const storage = app.storage()  

  const store_resource_file = (work_location, file_name, file_path) => {
   
  }


  const store_questions_file = (work_location, file) => {
    const questionsRef = storage_ref(storage, work_location + '/Questions/questions.xlsx');
    
    return uploadBytes(questionsRef, file).then((snapshot) => {
      console.log(snapshot)
    });
  }


  


  // const is_user_admin = async (email) => {
  //   var email = 'dew@sephora.com'
  //   var user_key = email.split('@')[0].replace(/[^a-z0-9+]+/gi, '_');
  //   const userRef = database_ref(db, 'users/' + user_key);
  //   onValue(userRef, (snapshot) => {
  //     const data = snapshot.val();  
  //     return data    
  //     // console.log(userRef)
  //   });

  // }

  // const getQuestionsFile = (work_location) => {
  //   getDownloadURL(ref(storage, work_location+'/Questions/questions.xlsx'))
  //     .then((url) => {
  //       // `url` is the download URL for 'images/stars.jpg'

  //       // This can be downloaded directly:
  //       const xhr = new XMLHttpRequest();
  //       xhr.responseType = 'blob';
  //       xhr.onload = (event) => {
  //         const blob = xhr.response;
  //       };
  //       xhr.open('GET', url);
  //       xhr.send();
  //     })

  //     .catch((error) => {
  //       // Handle any errors

  //     });
  // }
// check if we are even using the first 3 functions anywhere

  // const signInWithEmailAndPassword = async (email, password) => {
  //   if (email.endsWith("@sephora.com")){
  //     firebase.auth().signInWithEmailAndPassword(email, password)
  //       .then((userCredential) => {
  //         // Signed in
  //         var user = userCredential.user;
  //       })
  //       .catch((error) => {
  //         var errorCode = error.code;
  //         var errorMessage = error.message;
  //         if (errorCode=="auth/user-not-found"){
  //           return 'error'
  //         }
  //       });
  //   }   
  // };


  // const sendPasswordResetEmail = async (email) => {
  //   try {
  //     await auth.sendPasswordResetEmail(email);
  //   } catch (err) {
  //     console.error(err);
  //     alert(err.message);
  //   }
  // };


  // const logout = async () => {
  //   await auth.signOut()
  // }


  export {
    storage_ref,
    storage,
    auth,
    db,
    store_resource_file,
    store_questions_file,
    browser_local_persistence
    // getQuestionsFile
    // signInWithEmailAndPassword,
    // sendPasswordResetEmail,
    // logout,
  };